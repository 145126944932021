* {
  border-radius: 0;
}
body {
  background-color: #f7f7f7;
  color: #222;
  font-family: 'Abel', sans-serif;
  text-rendering: optimizelegibility;
  font-size: 18px;
}
@media (max-width: 991px) {
  body.header-fixed-space,
  body.header-fixed-space-v2 {
	  padding-top: 0;
  }
}
p,
li,
li a,
label {
  color: #222;
}
a {
  color: #72c02c;
  text-decoration: none;
}
a,
a:focus,
a:hover,
a:active,
button,
button:hover {
  outline: 0 !important;
}
a:focus {
  text-decoration: none;
}
a:hover {
  color: #72c02c;
  text-decoration: underline;
}
.copyright a:hover {
	color: #446868 !important;
}
.boxed-layout {
  padding: 0;
  box-shadow: 0 0 5px #ccc;
  margin-left: auto !important;
  margin-right: auto !important;
  background: url(../img/patterns/15.png) repeat;
}
select::-ms-expand {
  display: none;
}
@media (max-width: 991px) {
	 body.header-fixed-space-v2 {
		  padding-top: 0;
	 }
}
h1, h2, h3, h4, h5, h6 {
	 font-family: 'Abel', sans-serif;
}
.wrapper {
	 background: #fff;
}
#topcontrol {
	 color: #fff;
	 z-index: 99;
	 width: 30px;
	 height: 30px;
	 font-size: 20px;
	 background: #222;
	 position: relative;
	 right: 14px !important;
	 bottom: 11px !important;
	 border-radius: 10% !important;
}
#topcontrol:after {
	 top: -2px;
	 left: 8px;
	 content: "\f106";
	 position: absolute;
	 text-align: center;
	 font-family: FontAwesome;
}
#topcontrol:hover {
	 color: #fff;
	 transition: all 0.35s, transform 0.35s;
   background: #446868;
}
.blog-grid img {
	 margin-bottom: 15px;
}
.blog-grid h3 {
	 font-size: 22px;
	 margin: 0 0 10px;
	 line-height: 1.4;
}
.blog-grid h2.blog-grid-title-lg {
	 font-size: 28px;
	 line-height: 1.4;
	 margin: 0 0 10px;
}
.blog-grid h3.blog-grid-title-sm {
	 font-size: 15px;
	 line-height: 1.4;
}
.blog-grid h3 a {
	 color: #333;
}
.blog-grid p {
	 margin-bottom: 15px;
}
.blog-grid-inner {
	 padding: 20px;
	 background: #fff;
	 margin: -70px 40px 0;
	 position: relative;
}
.blog-grid .blog-grid-grad {
	 position: relative;
}
.blog-grid .blog-grid-grad i {
	 top: 10px;
	 left: 10px;
	 z-index: 1;
	 color: #fff;
	 width: 30px;
	 height: 30px;
	 font-size: 18px;
	 line-height: 30px;
	 text-align: center;
	 position: absolute;
	 display: inline-block;
	 background: rgba(0,0,0,0.5);
}
.blog-grid .blog-grid-info {
	 padding-left: 0;
	 list-style: none;
}
.blog-grid .blog-grid-info li {
	 color: #888;
	 padding: 0 2px;
	 font-size: 12px;
	 display: inline-block;
}
.blog-grid .blog-grid-info li:before {
	 content: '/';
	 font-size: 12px;
	 line-height: 1.4;
	 margin-right: 9px;
}
.blog-grid .blog-grid-info li:first-child:before {
	 content: " ";
	 margin-right: 0;
}
.blog-grid .blog-grid-info li a {
	 color: #888;
}
.blog-grid .blog-grid-info li a:hover {
	 text-decoration: none;
}
.blog-grid a.r-more {
	 font-size: 13px;
	 font-weight: 400;
	 font-style: italic;
	 display: inline-block;
}
.blog-grid a.r-more:hover {
	 text-decoration: none;
}
.bg-color-dark, .bg-color-darker, .bg-color-sea, .bg-color-red, .bg-color-aqua, .bg-color-blue, .bg-color-grey, .bg-color-light, .bg-color-green, .bg-color-brown, .bg-color-orange, .bg-color-green1, .bg-color-purple, .bg-color-dark-blue, .bg-color-light-grey, .bg-color-light-green {
  color: inherit;
}
.form-control {
	 height: auto;
}
hr {
	 margin: 30px 0;
}
hr.hr-xs {
	 margin: 10px 0;
}
hr.hr-md {
	 margin: 20px 0;
}
hr.hr-lg {
	 margin: 40px 0;
}
h1 {
  margin-top: 40px;
  margin-bottom: 25px;
  font-size: 36px;
  line-height: 60px;
}
.page-18 h1 {
	margin-top: 5px;
}
h2 {
	 font-size: 28px;
}
h3 {
	 font-size: 20px;
	 font-weight: bold;
	 margin-top: 25px;
}
.font-open-sans {
	 font-family: "Abel", Arial, sans-serif;
}
h1, h2, h3, h4, h5, h6 {
	 color: #555;
	 text-shadow: none;
	 font-family: "Abel", Arial, sans-serif;
}
h1 i, h2 i, h3 i, h4 i, h5 i, h6 i {
	 margin-right: 5px;
}
.headline {
	 display: block;
	 margin: 10px 0 25px 0;
	 border-bottom: 1px dotted #e4e9f0;
}
.headline h2 {
	 font-size: 36px;
}
.headline h2,
.headline h3,
.headline h4 {
	 margin: 0 0 -2px 0;
	 padding-bottom: 5px;
	 display: inline-block;
}
.headline-md {
	margin-bottom: 15px;
}
.headline-md h2 {
	 font-size: 21px;
}
.heading {
	 text-align: center;
}
.heading h2 {
	 padding: 0 12px;
	 position: relative;
	 display: inline-block;
	 line-height: 34px !important;
}
.heading h2:before,
.heading h2:after {
	 content: ' ';
	 width: 70%;
	 position: absolute;
	 border-width: 1px;
	 border-color: #bbb;
}
.heading h2:before {
	 right: 100%;
}
.heading h2:after {
	 left: 100%;
}
@media (max-width: 768px) {
	 .heading h2:before,
	 .heading h2:after {
		  width: 20%;
	 }
}
h1.title-light,
h2.title-light,
h3.title-light {
	 color: #fff;
}
h2.title-light:after {
	 background: #fff;
}
.headline-center-v2 {
	 z-index: 0;
	 text-align: center;
	 position: relative;
}
.headline-center-v2 h2 {
	 color: #ddd;
	 font-size: 36px;
	 margin-bottom: 20px;
	 text-transform: uppercase;
	 letter-spacing: .1em;
}
.headline-center-v2 span.bordered-icon {
	 color: #fff;
	 padding: 0 10px;
	 font-size: 15px;
	 line-height: 18px;
	 position: relative;
	 margin-bottom: 25px;
	 display: inline-block;
}
.headline-center-v2 span.bordered-icon:before,
.headline-center-v2 span.bordered-icon:after {
	 top: 8px;
	 height: 1px;
	 content: " ";
	 width: 100px;
	 background: #fff;
	 position: absolute;
}
.headline-center-v2 span.bordered-icon:before {
	 left: 100%;
}
.headline-center-v2 span.bordered-icon:after {
	 right: 100%;
}
.headline-center-v2 p {
	 color: #555;
	 font-size: 20px;
	 padding: 0 40px;
}
.headline-center-v2.headline-center-v2-dark p {
 	color: #444;
}
.headline-center-v2.headline-center-v2-dark span.bordered-icon {
	 color: #444;
}
.headline-center-v2.headline-center-v2-dark span.bordered-icon:before,
.headline-center-v2.headline-center-v2-dark span.bordered-icon:after {
	 background: #444;
}
h2.heading-md {
	 font-size: 20px;
	 line-height: 24px;
}
h2.heading-sm,
h3.heading-md {
	 font-size: 18px;
	 line-height: 24px;
}
h3.heading-md {
	 line-height: 22px;
}
h3.heading-sm {
	 font-size: 16px;
	 line-height: 20px;
}
h2.heading-xs {
	 font-size: 16px;
	 line-height: 22px;
}
h3.heading-xs {
	 font-size: 14px;
	 margin-bottom: 0;
}
.table thead > tr > th {
	 border-bottom: none;
}
@media (max-width: 768px) {
	 .table th.hidden-sm,
	 .table td.hidden-sm {
		  display: none !important;
	 }
}
.form-control {
	 box-shadow: none;
	 border-radius: 0;
}
.input-group-addon {
	 border-radius: 0;
}
.form-control:focus {
	 border-color: #bbb;
	 box-shadow: 0 0 2px #c9c9c9;
}
.form-spacing .form-control {
	 margin-bottom: 15px;
}
.input-group-addon {
	 color: #b3b3b3;
	 font-size: 14px;
	 background: #fff;
}
.dropdown-menu,
.navbar-toggle {
	 border-radius: 0;
}
.btn {
	 box-shadow: none;
	 border-radius: 0;
}
.btn-u {
 	border: 0;
 	color: #fff;
 	font-size: 14px;
 	cursor: pointer;
	 font-weight: 400;
	 padding: 6px 13px;
	 position: relative;
	 background: #72c02c;
	 white-space: nowrap;
	 display: inline-block;
	 text-decoration: none;
}
.btn-u:hover {
	 color: #fff;
	 text-decoration: none;
	 transition: all 0.3s ease-in-out;
}
.btn-u.btn-block {
	 text-align: center;
}
.btn-u-sm,
a.btn-u-sm {
	 padding: 3px 12px;
}
.btn-u-md,
a.btn-u-md {
	 padding: 10px 20px;
}
.btn-u-lg,
a.btn-u-lg {
	 font-size: 18px;
	 padding: 10px 25px;
}
.btn-u-xs,
a.btn-u-xs {
	 font-size: 12px;
	 padding: 2px 12px;
	 line-height: 18px;
}
.btn-group .dropdown-menu > li > a {
	 padding: 3px 13px;
}
.btn-group > .btn-u,
.btn-group-vertical > .btn-u {
	 float: left;
	 position: relative;
}
.btn-group > .btn-u:first-child {
	 margin-left: 0;
}
@-moz-document url-prefix() {
	 .footer-subscribe .btn-u {
	  	padding-bottom: 4px;
	 }
}
@media (max-width: 768px) {
	 @-moz-document url-prefix() {
		  .btn-u {
			   padding-bottom: 6px;
		  }
	 }
}
.btn-u:hover,
.btn-u:focus,
.btn-u:active,
.btn-u.active,
.open .dropdown-toggle.btn-u {
	 background: #5fb611;
}
.btn-u-split.dropdown-toggle {
	 border-left: solid 1px #5fb611;
}
.btn-u.btn-u-default {
	 background: #95a5a6;
}
.btn-u.btn-u-default:hover,
.btn-u.btn-u-default:focus,
.btn-u.btn-u-default:active,
.btn-u.btn-u-default.active,
.open .dropdown-toggle.btn-u.btn-u-default {
	 background: #7f8c8d;
}
.btn-u.btn-u-split-default.dropdown-toggle {
	 border-left: solid 1px #7f8c8d;
}
.btn-u.btn-u-dark {
	 background: #555;
}
.btn-u.btn-u-dark:hover,
.btn-u.btn-u-dark:focus,
.btn-u.btn-u-dark:active,
.btn-u.btn-u-dark.active,
.open .dropdown-toggle.btn-u.btn-u-dark {
	 background: #333;
}
.btn-u.btn-u-split-dark.dropdown-toggle {
	 border-left: solid 1px #333;
}
.btn-u.btn-brd {
	 color: #555;
	 background: none;
	 padding: 5px 13px;
	 border: solid 1px transparent;
}
.btn-u.btn-brd-width-2 {
	 padding: 7px 18px;
	 border-width: 2px;
}
.btn-u.btn-brd:hover {
	 background: none;
}
.btn-u.btn-brd:focus {
	 background: none;
}
.btn-u.btn-brd.btn-brd-hover:hover {
	 color: #fff !important;
}
.btn-u.btn-brd {
	 border-color: #72c02c;
}
.btn-u.btn-brd:hover {
	 color: #5fb611;
	 border-color: #5fb611;
}
.btn-u.btn-brd.btn-brd-hover:hover {
	 background: #5fb611;
}
.btn-u.btn-brd.btn-u-default {
	 border-color: #95a5a6;
}
.btn-u.btn-brd.btn-u-default:hover {
	 color: #7f8c8d;
	 border-color: #7f8c8d;
}
.btn-u.btn-brd.btn-u-default.btn-brd-hover:hover {
	 background: #7f8c8d;
}
.btn-u.btn-brd.btn-u-dark {
	 border-color: #555;
}
.btn-u.btn-brd.btn-u-dark:hover {
	 color: #333;
	 border-color: #333;
}
.btn-u.btn-brd.btn-u-dark.btn-brd-hover:hover {
	 background: #333;
}
.btn-u.btn-brd.btn-u-light {
	 color: #fff;
	 border-color: #fff;
}
.btn-u.btn-brd.btn-u-light:hover {
	 border-color: #fff;
}
.btn-u.btn-brd.btn-u-light.btn-brd-hover:hover {
  background: #fff;
  color: #555 !important;
}
.dropdown-show {
	 box-shadow: 0 0 4px #eee;
	 display: inline-block;
	 position: relative;
}
.social-icons {
	 margin: 0;
	 padding: 0;
}
.social-icons li {
	 list-style: none;
	 margin-right: 3px;
	 margin-bottom: 5px;
	 text-indent: -9999px;
	 display: inline-block;
}
.social-icons li a, a.social-icon {
	 width: 28px;
	 height: 28px;
	 display: block;
	 background-position: 0 0;
	 background-repeat: no-repeat;
	 transition: all 0.3s ease-in-out;
}
.social-icons li:hover a {
	 background-position: 0 -38px;
}
.social-icons-color li a {
	 opacity: 0.7;
	 background-position: 0 -38px !important;
	 -webkit-backface-visibility: hidden;
}
.social-icons-color li a:hover {
	 opacity: 1;
}
.social_facebook {background: url(../img/icons/social/facebook.png) no-repeat;}
.social_instagram {background: url(../img/icons/social/instagram.png) no-repeat;}
i.icon-custom {
	 color: #555;
	 width: 40px;
	 height: 40px;
	 font-size: 20px;
	 line-height: 40px;
	 margin-bottom: 5px;
	 text-align: center;
	 display: inline-block;
	 border: solid 1px #555;
}
i.icon-sm {
  width: 35px;
  height: 35px;
  font-size: 16px;
  line-height: 35px;
}
i.icon-md {
	 width: 55px;
	 height: 55px;
	 font-size: 22px;
	 line-height: 55px;
}
i.icon-lg {
	 width: 60px;
	 height: 60px;
	 font-size: 31px;
	 line-height: 60px;
	 margin-bottom: 10px;
}
i.icon-2x {
	 font-size: 30px;
}
i.icon-3x {
	 font-size: 40px;
}
i.icon-4x {
	 font-size: 50px;
}
i.icon-line {
	 font-size: 17px;
}
i.icon-sm.icon-line {
	 font-size: 14px;
}
i.icon-md.icon-line {
	 font-size: 22px;
}
i.icon-lg.icon-line {
	 font-size: 28px;
}
i.icon-2x.icon-line {
	 font-size: 27px;
}
i.icon-3x.icon-line {
	 font-size: 36px;
}
i.icon-4x.icon-line {
	 font-size: 47px;
}
.link-icon,
.link-bg-icon {
	 color: #555;
}

.link-icon:hover,
.link-bg-icon:hover {
	 border: none;
	 text-decoration: none;
}
.link-icon:hover i {
	 color: #72c02c;
	 background: none;
	 border: solid 1px #72c02c;
}
.link-bg-icon:hover i {
	 color: #72c02c;
	 background: #72c02c;
	 border-color: #72c02c;
	 color: #fff !important;
}
i.icon-color-u,
i.icon-color-red,
i.icon-color-sea,
i.icon-color-dark,
i.icon-color-grey,
i.icon-color-blue,
i.icon-color-green,
i.icon-color-yellow,
i.icon-color-orange,
i.icon-color-purple,
i.icon-color-aqua,
i.icon-color-brown,
i.icon-color-dark-blue,
i.icon-color-light-grey,
i.icon-color-light-green {
 	background: none;
}
i.icon-color-u {
	 color: #72c02c;
	 border: solid 1px #72c02c;
}
i.icon-color-light {
	 color: #fff;
	 border: solid 1px #fff;
}
i.icon-bg-u,
i.icon-bg-red,
i.icon-bg-sea,
i.icon-bg-dark,
i.icon-bg-darker,
i.icon-bg-grey,
i.icon-bg-blue,
i.icon-bg-green,
i.icon-bg-yellow,
i.icon-bg-orange,
i.icon-bg-purple,
i.icon-bg-aqua,
i.icon-bg-brown,
i.icon-bg-dark-blue,
i.icon-bg-light-grey,
i.icon-bg-light-green {
	 color: #fff;
	 border-color: transparent;
}
i.icon-bg-u {
	 background: #72c02c;
}
i.icon-bg-dark {
	 background: #555;
}
i.icon-bg-darker {
	 background: #333;
}
i.icon-bg-light {
	 background: #fff;
	 border-color: transparent;
}
.fa-fixed [class^="fa"],
.fa-fixed [class*=" fa"] {
	 width: 1.25em;
	 text-align: center;
	 display: inline-block;
}
.fa-fixed [class^="fa"].fa-lg,
.fa-fixed [class*=" fa"].fa-lg {
	 width: 1.5625em;
}
.text-justify p { text-align: justify;}
.text-transform-uppercase { text-transform: uppercase;}
.text-transform-normal { text-transform: inherit !important;}
.font-bold { font-weight: 600;}
.font-light { font-weight: 200;}
.font-normal { font-weight: 400 !important;}
.text-highlights {
	 color: #fff;
	 font-weight: 200;
	 padding: 0px 5px;
	 background: #555;
}
.list-styles li {
	 margin-bottom: 8px;
}
blockquote {
	 padding: 5px 15px;
	 border-left-width: 2px;
}
blockquote p {
	 font-size: 14px;
	 font-weight: 400;
}
blockquote h1,
blockquote h2,
blockquote span {
	 font-size: 18px;
	 margin: 0 0 8px;
	 line-height: 24px;
}
blockquote.bq-text-lg p,
blockquote.bq-text-lg small {
	 text-transform: uppercase;
}
blockquote.bq-text-lg p {
	 font-size: 22px;
	 font-weight: 300;
	 line-height: 32px;
}
blockquote.text-right,
blockquote.hero.text-right {
	 border-left: none;
	 border-right: 2px solid #eee;
}
blockquote.hero.text-right,
blockquote.hero.text-right:hover {
	 border-color: #555;
}
blockquote:hover,
blockquote.text-right:hover {
	 border-color: #72c02c;
	 transition: all 0.4s ease-in-out;
}
blockquote.bq-dark,
blockquote.bq-dark:hover {
	 border-color: #585f69;
}
blockquote.bq-green {
	 border-color: #72c02c;
}
blockquote.hero {
	 border: none;
	 padding: 18px;
	 font-size: 16px;
	 background: #f3f3f3;
	 border-left: solid 2px #666;
}
blockquote.hero:hover {
	 background: #eee;
	 border-left-color: #666;
}
blockquote.hero.hero-dark,
blockquote.hero.hero-default {
	 border: none;
}
blockquote.hero.hero-dark {
	 background: #444;
}
blockquote.hero.hero-dark:hover {
	 background: #555;
}
blockquote.hero.hero-default {
	 background: #72c02c;
}
blockquote.hero.hero-default:hover {
	 background: #5fb611;
}
blockquote.hero.hero-dark p,
blockquote.hero.hero-dark h2,
blockquote.hero.hero-dark small,
blockquote.hero.hero-default p,
blockquote.hero.hero-default h2,
blockquote.hero.hero-default small {
	 color: #fff;
	 font-weight: 200;
}
.list-row {
	 padding: 0;
	 margin-bottom: 0;
	 list-style: none;
}
.alert {
	 border-radius: 0;
}
.thumbnail {
	 border-radius: 0;
}
.progress {
	 border-radius: 0;
}
.progress-u {
	 box-shadow: none;
}
.progress-u .progress-bar {
	 box-shadow: none;
}
.progress-lg {
	 height: 25px;
}
.progress-lg p {
	 padding-top: 3px;
}
.progress-sm {
	 height: 12px;
}
.progress-xs {
	 height: 7px;
}
.progress-xxs {
	 height: 3px;
}
.progress {
	 background: #e5e5e5;
}
.progress-bar-u {
	 background: #72c02c;
}
.progress-bar-dark {
	 background: #555;
}
.progress {
		position: relative;
}
.progress .progress-bar {
	 overflow: hidden;
	 line-height: 20px;
}
.progress-box .progress-bar {
	 transition: all 3s ease-in;
}
.progress.vertical {
	 float: left;
	 width: 100%;
	 height: 200px;
	 margin-right: 20px;
}
.progress.vertical.bottom {
	 position: relative;
}
.progress.vertical .progress-bar {
	 height: 0;
	 width: 100%;
	 transition: height 3s ease;
}
.progress.vertical.bottom .progress-bar {
	 bottom: 0;
	 position: absolute;
}
img.img-bordered {
	 padding: 3px !important;
	 border: solid 1px #eee !important;
}
img.img-circle {
	 border-radius: 50% !important;
}
img.image-sm {
	 width: 50px;
	 height: 50px;
}
img.image-md {
	 width: 100px;
	 height: 100px;
}
.responsive-video {
  height: 0;
  padding-top: 1px;
  position: relative;
  padding-bottom: 56.25%;
}
.responsive-video iframe {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  position: absolute;
}
.no-gutter > [class*='col-'] {
	 padding-right: 0;
	 padding-left: 0;
}
.no-gutter.no-gutter-boxed {
	 padding-right: 15px;
	 padding-left: 15px;
}
.height-100 { min-height: 100px;}
.height-150 { min-height: 150px;}
.height-200 { min-height: 200px;}
.height-250 { min-height: 250px;}
.height-300 { min-height: 300px;}
.height-350 { min-height: 350px;}
.height-400 { min-height: 400px;}
.height-450 { min-height: 450px;}
.height-500 { min-height: 500px !important;}
.no-padding {
	 padding: 0 !important;
}
.no-margin {
	 margin: 0;
}
.no-top-space {
	 margin-top: 0 !important;
	 padding-top: 0 !important;
}
.no-bottom-space {
	 margin-bottom: 0 !important;
	 padding-bottom: 0 !important;
}
.no-margin-bottom {
	 margin-bottom: 0 !important;
}
.no-padding-bottom {
	 padding-bottom: 0 !important;
}
.content-xs {
	 padding-top: 20px;
	 padding-bottom: 20px;
}
.content {
	 padding-top: 40px;
	 padding-bottom: 40px;
}
.content-sm {
	 padding-top: 60px;
	 padding-bottom: 60px;
}
.content-md {
	 padding-top: 80px;
	 padding-bottom: 80px;
}
.content-lg {
	 padding-top: 100px;
	 padding-bottom: 100px;
}
.content-xlg {
	 padding-top: 150px;
	 padding-bottom: 150px;
}
.space-md-hor {
	 padding-left: 40px;
	 padding-right: 40px;
}
.space-lg-hor {
	 padding-left: 60px;
	 padding-right: 60px;
}
.space-xlg-hor {
	 padding-left: 100px;
	 padding-right: 100px;
}
.margin-bottom-5,
.margin-bottom-10,
.margin-bottom-15,
.margin-bottom-20,
.margin-bottom-25,
.margin-bottom-30,
.margin-bottom-35,
.margin-bottom-40,
.margin-bottom-45,
.margin-bottom-50,
.margin-bottom-55,
.margin-bottom-60,
.margin-bottom-100 {
	 clear:both;
}
.margin-bottom-5  { margin-bottom:5px;}
.margin-bottom-10 { margin-bottom:10px;}
.margin-bottom-15 { margin-bottom:15px;}
.margin-bottom-20 { margin-bottom:20px;}
.margin-bottom-25 { margin-bottom:25px;}
.margin-bottom-30 { margin-bottom:30px;}
.margin-bottom-35 { margin-bottom:35px;}
.margin-bottom-40 { margin-bottom:40px;}
.margin-bottom-45 { margin-bottom:45px;}
.margin-bottom-50 { margin-bottom:50px;}
.margin-bottom-55 { margin-bottom:55px;}
.margin-bottom-60 { margin-bottom:60px;}
.margin-bottom-70 { margin-bottom:70px;}
.margin-bottom-80 { margin-bottom:80px;}
.margin-bottom-90 { margin-bottom:90px;}
.margin-bottom-100 { margin-bottom:100px;}
@media (max-width: 768px) {
	 .sm-margin-bottom-10 {
		  margin-bottom: 10px;
	 }
	 .sm-margin-bottom-20 {
		  margin-bottom: 20px;
	 }
	 .sm-margin-bottom-30 {
		  margin-bottom: 30px;
	 }
	 .sm-margin-bottom-40 {
		  margin-bottom: 40px;
	 }
	 .sm-margin-bottom-50 {
		  margin-bottom: 50px;
	 }
	 .sm-margin-bottom-60 {
		  margin-bottom: 60px;
	 }
}
@media (max-width: 992px) {
	 .md-margin-bottom-10 {
		  margin-bottom: 10px;
	 }
	 .md-margin-bottom-20 {
		  margin-bottom: 20px;
	 }
	 .md-margin-bottom-30 {
		  margin-bottom: 30px;
	 }
	 .md-margin-bottom-40 {
		  margin-bottom: 40px;
	 }
	 .md-margin-bottom-50 {
		  margin-bottom: 50px;
	 }
	 .md-margin-bottom-60 {
		  margin-bottom: 60px;
	 }
}
.margin-top-20 { margin-top: 20px;}
.margin-left-5 { margin-left: 5px;}
.margin-left-10 { margin-left: 10px;}
.margin-right-5 { margin-right: 5px;}
.margin-right-10 { margin-right: 10px;}
.margin-right-20 { margin-right: 20px;}
.padding-sm { padding: 40px 40px;}
.padding-top-5 { padding-top: 5px;}
.padding-left-5 { padding-left: 5px;}
.color-sea { color: #1abc9c;}
.color-red { color: #e74c3c;}
.color-aqua { color: #27d7e7;}
.color-blue { color: #3498db;}
.color-grey { color: #95a5a6;}
.color-dark { color: #555;}
.color-darker { color: #444;}
.color-green { color: #72c02c;}
.color-brown { color: #9c8061;}
.color-light { color: #ffffff;}
.color-orange { color: #e67e22;}
.color-yellow { color: #f1c40f;}
.color-green1 { color: #2ecc71;}
.color-purple { color: #9b6bcc;}
.color-inherit { color: inherit;}
.color-dark-blue { color: #4765a0;}
.color-light-grey { color: #585f69;}
.color-light-green { color: #79d5b3;}
.color-teal { color: #18ba9b;}
.bg-color-dark,
.bg-color-darker,
.bg-color-sea,
.bg-color-red,
.bg-color-aqua,
.bg-color-blue,
.bg-color-grey,
.bg-color-light,
.bg-color-green,
.bg-color-brown,
.bg-color-orange,
.bg-color-green1,
.bg-color-purple,
.bg-color-dark-blue,
.bg-color-light-grey,
.bg-color-light-green,
.bg-color-primary {
	 color: #fff;
}
.bg-color-white {
	 color: #555;
}
.bg-color-primary { background-color: #72c02c !important;}
.bg-color-dark { background-color: #555 !important;}
.bg-color-darker { background-color: #222 !important;}
.bg-color-white { background-color: #fff !important;}
.bg-color-sea { background-color: #1abc9c !important;}
.bg-color-red { background-color: #e74c3c !important;}
.bg-color-aqua { background-color: #27d7e7 !important;}
.bg-color-blue { background-color: #3498db !important;}
.bg-color-grey { background-color: #95a5a6 !important;}
.bg-color-light { background-color: #f7f7f7 !important;}
.bg-color-green { background-color: #72c02c !important;}
.bg-color-brown { background-color: #9c8061 !important;}
.bg-color-orange { background-color: #e67e22 !important;}
.bg-color-green1 { background-color: #2ecc71 !important;}
.bg-color-purple { background-color: #9b6bcc !important;}
.bg-color-dark-blue { background-color: #4765a0 !important;}
.bg-color-light-grey { background-color: #585f69 !important;}
.bg-color-light-green { background-color: #79d5b3 !important;}
.bg-color-teal { background-color: #18ba9b !important; }
.rgba-red { background-color: rgba(231,76,60,0.8)}
.rgba-blue { background-color: rgba(52,152,219,0.8);}
.rgba-aqua { background-color: rgba(39,215,231,0.8);}
.rgba-yellow { background-color: rgba(241,196,15,0.8);}
.rgba-default { background-color: rgba(114,192,44,0.8);}
.rgba-purple { background-color: rgba(155,107,204,0.8);}
.bg-grey {
	 background: #f7f7f7;
	 border-top: solid 1px #eee;
	 border-bottom: solid 1px #eee;
}
.no-rounded { border-radius: 0 !important;}
.rounded { border-radius: 4px !important;}
.rounded-x { border-radius: 50% !important;}
.rounded-2x { border-radius: 10px !important;}
.rounded-3x { border-radius: 15px !important;}
.rounded-4x { border-radius: 20px !important;}
.rounded-sm { border-radius: 2px !important;}
.rounded-md { border-radius: 3px !important;}
.rounded-top { border-radius: 4px 4px 0 0 !important;}
.rounded-left { border-radius: 4px 0 0 4px !important;}
.rounded-right { border-radius: 0 4px 4px 0 !important;}
.rounded-bottom { border-radius: 0 0 4px 4px !important;}
.overflow-h { overflow: hidden;}
.overflow-a { overflow: auto;}
.overflow-hidden { overflow: hidden;}
.clear-both { clear: both;}
.dp-none { display: none;}
.dp-block { display: block;}
.dp-table { display: table;}
.dp-inline-block { display: inline-block;}
.dp-table-cell {
	 display: table-cell;
	 vertical-align: middle;
}
.text-light {
	 font-weight: 200;
}
.text-normal {
	 font-weight: 400;
}
.text-bold {
	 font-weight: 600 !important;
}
.full-width {
	 width: 100%;
}
@media (max-width: 767px) {
	 .equal-height-column {
		  height: auto !important;
	 }
}
.img-width-60 { width: 60px;}
.img-width-200 { width: 200px;}
.lft-img-margin { margin:0 20px 5px 0;}
.rgt-img-margin { margin:0 0 5px 10px;}
img.img-center,
.img-center img {
	 margin-left: auto;
	 margin-right: auto;
}
.bg-light {
  padding: 10px 15px;
  margin-bottom: 10px;
  background: #fcfcfc;
  border: solid 1px #e5e5e5;
}
.bg-light:hover {
	 border: solid 1px #bbb;
}
.hover-effect {
  transition: all 0.4s ease-in-out;
}
.hover-effect-kenburn {
  left:10px;
  margin-left:-10px;
  position:relative;
  transition: all 0.8s ease-in-out;
}
.hover-effect-kenburn:hover {
  transform: scale(2) rotate(5deg);
}
.scroll-btn-icon,
.scroll-btn-icon:before {
 	position: absolute;
 	left: 50%;
}
.scroll-btn-icon {
	 width: 30px;
	 z-index: 2;
	 height: 50px;
	 bottom: 30px;
	 margin-left: -25px;
	 border-radius: 25px;
	 box-shadow: inset 0 0 0 1px rgba(255,255,255,0.5);
}
.scroll-btn-icon-dark {
	 box-shadow: inset 0 0 0 1px rgba(0,0,0,0.5);
}
.scroll-btn-icon:before {
	 top: 8px;
	 width: 8px;
	 height: 8px;
	 content: '';
	 background: #fff;
	 margin-left: -4px;
	 border-radius: 4px;
	 animation-name: scroll;
	 animation-duration: 1.5s;
	 -webkit-animation-name: scroll;
	 -webkit-animation-duration: 1.5s;
	 animation-iteration-count: infinite;
	 -webkit-animation-iteration-count: infinite;
}
.scroll-btn-icon-dark:before {
	 background: #333;
}
@keyframes scroll {
	 0% {
		  opacity: 1;
	 }
	 100% {
		  opacity: 0;
		  transform: translateY(36px);
		  -webkit-transform: translateY(36px);
	 }
}
@media (min-width: 768px) {
	.g-blur { overflow: hidden; }
 	.g-blur .wrapper,
	.g-blur .outside-more-articles {
		  filter: url('data:image/svg+xml;utf8,<svg version="1.1" xmlns="//www.w3.org/2000/svg"><filter id="blur"><feGaussianBlur stdDeviation="7" /></filter></svg>#blur');
		  filter: progid:DXImageTransform.Microsoft.Blur(PixelRadius='7');
		  -moz-filter:blur(7px);
		  -ms-filter: blur(7px);
		  filter: blur(7px);
	 }
	 body.header-fixed-space-v2.g-blur { padding-top: 0; }
}
.img-hover-v1 span {
  display: block;
  overflow: hidden;
  position: relative;
}
.img-hover-v1 span:after {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  content: " ";
  position: absolute;
}
.img-hover-v1:hover span:after {
  background: rgba(0,0,0,0.3);
}
.img-hover-v1 img,
.img-hover-v2 img,
.img-hover-v1 span:after {
  transition: all 0.5s ease-in-out;
}
.img-hover-v1:hover img {
  -transform: scale(1.1);
}
.img-hover-v2 img {
  filter: grayscale(100%);
  -webkit-filter: grayscale(100%);
}
.img-hover-v2:hover img {
  filter: grayscale(0);
  -webkit-filter: grayscale(0);
}
@media (min-width: 991px) and (max-width: 1199px) {
  .portfolio-box {
    margin-bottom: 60px;
  }
}
@media (min-width: 768px) and (max-width: 992px) {
  .portfolio-box {
    margin-bottom: 0;
  }
}
@media (max-width: 768px) {
  .portfolio-box {
    margin-bottom: 60px;
  }
}
.portfolio-box a.fancybox {
  overflow: hidden;
  position: relative;
}
.portfolio-box a.fancybox:after {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  content: " ";
  position: absolute;
}
.portfolio-box a.fancybox:hover:after {
  background: rgba(0,0,0,0.5);
}
.portfolio-box a.fancybox img {
  transition: all .5s ease;
}
.portfolio-box a.fancybox:hover img {
  transform: scale(1.1);
}
.portfolio-box .portfolio-box-in {
	visibility: hidden;
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 1;
  color: #fff;
	background: #72c02c;
	font-size: 20px;
	text-align: center;
	line-height: 53px;
	width: 50px;
	height: 50px;
  margin-top: -25px;
  margin-left: -25px;
}
.portfolio-box a.fancybox:hover .portfolio-box-in {
  visibility: visible;
}
.portfolio-box small.project-tag {
  display: block;
  font-size: 12px;
  margin-bottom: 5px;
}
.portfolio-box small.project-tag,
.portfolio-box small.project-tag a {
  color: #999;
}
.portfolio-box small.project-tag i {
  margin-right: 5px;
}
.portfolio-box small.project-tag a:hover {
  color: #72c02c;
}
.portfolio-box-v2 {
  margin: 0 0 60px;
}
.portfolio-box-v2 [class^="col-"] {
  padding: 0;
  overflow: hidden;
}
.portfolio-box-v2 li {
  background: #333;
  overflow: hidden;
  position: relative;
  -webkit-backface-visibility: hidden;
}
.portfolio-box-v2 li:after {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  content: " ";
  position: absolute;
}
.portfolio-box-v2 li:hover:after {
	background: rgba(255,255,255,0.6);
}
.portfolio-box-v2 li:after,
.portfolio-box-v2 li:hover:after {
  transition: all 0.3s ease-in-out;
}
.portfolio-box-v2 li img {
  width: 100%;
  overflow: hidden;
  transition: all 0.3s ease-in-out;
  -transform: scale(1.1);
}
.portfolio-box-v2 .portfolio-box-v2-in {
  left: 0;
  top: 50%;
  z-index: 1;
  width: 100%;
  visibility: hidden;
  min-height: 60px;
  margin-top: -30px;
  text-align: center;
  position: absolute;
  font-family: "Open Sans", Arial, sans-serif;
}
.portfolio-box-v2 li:hover .portfolio-box-v2-in {
	visibility: visible;
}
.portfolio-box-v2 .portfolio-box-v2-in i {
	color: #fff;
	width: 60px;
	height: 60px;
	padding: 20px;
	font-size: 22px;
	cursor: pointer;
	margin-right: 5px;
	text-align: center;
	display: inline-block;
	background: rgba(114,192,44,0.8);
}
.portfolio-box-v2 .portfolio-box-v2-in i:hover {
	background: #72c02c;
}
.social-icons-v1 i {
  color: #555;
  width: 33px;
  height: 33px;
  padding: 9px;
  font-size: 14px;
  text-align: center;
  background: #f7f7f7;
  display: inline-block;
}
.social-icons-v1 i:hover {
  color: #fff;
  background: #72c02c;
  text-decoration: none;
  transition: all 0.2s ease-in-out;
}
.social-icons-v1--dark i {
  color: #fff;
  background: #333;
}
.shadow-wrapper {
  z-index: 1;
  position: relative;
}
.box-shadow {
  background: #fff;
  position: relative;
}
.box-shadow:after,
.box-shadow:before {
  top: 80%;
  left: 5px;
  width: 50%;
  z-index: -1;
  content: "";
  bottom: 15px;
  max-width: 300px;
  background: #999;
  position: absolute;
}
.shadow-effect-1 {
  box-shadow: 0 10px 6px -6px #bbb;
}
.shadow-effect-2 {
  position: relative;
}
.shadow-effect-2:after,
.shadow-effect-2:before,
.shadow-effect-3:before,
.shadow-effect-4:after {
  transform: rotate(-3deg);
  box-shadow: 0 15px 10px #999;
}
.shadow-effect-2:after,
.shadow-effect-4:after {
  left: auto;
  right: 5px;
  transform: rotate(3deg);
}
.shadow-effect-5 {
  box-shadow: 0 0 2px #ccc;
}
@keyframes infiniteBackground {
  from {
    background-position: 0 0;
  }
  to {
    background-position: 1024px 0;
  }
}
@-moz-keyframe infiniteBackground {
  from {
    background-position: 0 0;
  }
  to {
    background-position: 1024px 0;
  }
}
.blog-grid img {
  margin-bottom: 15px;
}
.blog-grid h3 {
  font-size: 22px;
  margin: 0 0 10px;
  line-height: 1.4;
}
.blog-grid h2.blog-grid-title-lg {
  font-size: 28px;
  line-height: 1.4;
  margin: 0 0 10px;
}
.blog-grid h3.blog-grid-title-sm {
  font-size: 15px;
  line-height: 1.4;
}
.blog-grid h3 a {
  color: #333;
}
.blog-grid p {
  margin-bottom: 15px;
}
.blog-grid-inner {
  padding: 20px;
  background: #fff;
  margin: -70px 40px 0;
  position: relative;
}
.blog-grid .blog-grid-grad {
  position: relative;
}
.blog-grid .blog-grid-grad i {
  top: 10px;
  left: 10px;
  z-index: 1;
  color: #fff;
  width: 30px;
  height: 30px;
  font-size: 18px;
  line-height: 30px;
  text-align: center;
  position: absolute;
  display: inline-block;
  background: rgba(0,0,0,0.5);
}
.blog-grid .blog-grid-info {
  padding-left: 0;
  list-style: none;
}
.blog-grid .blog-grid-info li {
  color: #888;
  padding: 0 2px;
  font-size: 12px;
  display: inline-block;
}
.blog-grid .blog-grid-info li:before {
  content: '/';
  font-size: 12px;
  line-height: 1.4;
  margin-right: 9px;
}
.blog-grid .blog-grid-info li:first-child:before {
  content: " ";
  margin-right: 0;
}
.blog-grid .blog-grid-info li a {
  color: #888;
}
.blog-grid .blog-grid-info li a:hover {
  text-decoration: none;
}
.blog-grid a.r-more {
  font-size: 13px;
  font-weight: 400;
  font-style: italic;
  display: inline-block;
}
.blog-grid a.r-more:hover {
  text-decoration: none;
}
.clients {
  border: 0;
  overflow: hidden;
  margin-top: 10px;
  margin-bottom: 30px;
  box-shadow:none !important;
  margin-bottom: 0 !important;
}
.clients li {
  overflow: hidden;
  margin-left: 1px;
  position: relative;
  background: #fafafa;
}
.clients li:hover {
  background: #f6f6f6;
}
.clients li img.color-img {
  top: 0;
  left: 0;
  display: none;
  position: absolute;
}
.clients li:hover img.color-img {
  display: block;
}
.clients li img {
  filter: gray; /* IE6-9 */
  float: none !important;
  margin: 0 auto !important;
}
.clients li img:hover {
  filter: none;
}
#topcontrol {
  color: #fff;
  z-index: 99;
  width: 30px;
  height: 30px;
  font-size: 20px;
  background: #222;
  position: relative;
  right: 14px !important;
  bottom: 11px !important;
  border-radius: 3px !important;
}
#topcontrol:after {
  top: -2px;
  left: 8.5px;
  content: "\f106";
  position: absolute;
  text-align: center;
  font-family: FontAwesome;
}
#topcontrol:hover {
  color: #fff;
  background: #72c02c;
  transition: all 0.3s ease-in-out;
}
.wrapper-5.partners .container {
	padding: 40px 0;
}
.owl-clients-v1 img {
  width: 140px;
  height: auto;
}
.owl-clients-v1 .owl-pagination {
    display: none;
}
.footer-logo {
	margin: 17px 0 20px;
}
.latest-list li {
	padding: 8px 0;
	border-top: 1px solid #353535;
}
.latest-list li:first-child {
	padding-top: 0;
	border-top: none;
}
.latest-list li a {
	color: #eee;
}
.latest-list small {
	color: #999;
	display: block;
}
.link-list li {
	border-top: solid 1px #353535;
}
.link-list a {
	color: #eee;
	font-size: 18px;
	padding: 8px 0 8px 0;
	display: inline-block;
  text-transform: uppercase;
}
.link-list li i {
	color: #bbb;
	float: right;
	margin-top: 10px;
}
.social-icons {
	margin-top: 5px;
}
.social-icons li {
	margin: 0;
}
.social-icons li a {
	margin: 0 3px;
}
a.thumbnail {
	padding: 0;
	border: none;
	margin-bottom: 15px;
}
.thumbnail-style {
	padding: 7px;
	margin-bottom: 20px;
}
.thumbnail-style:hover {
	box-shadow: 0 0 8px #ddd;
  transition: box-shadow 0.2s ease-in-out;
}
.thumbnail h3,
.thumbnail-style h3 {
	margin: 6px 0 8px 0;
}
.thumbnail h3 a,
.thumbnail-style h3 a {
	color: #585f69;
	font-size: 21px;
}
.thumbnail h3 a:hover,
.thumbnail h3 a:focus,
.thumbnail-style h3 a:hover,
.thumbnail-style h3 a:focus {
	color: #888;
	text-decoration: none;
}
.thumbnail-style.thumbnail-kenburn h3 a:hover,
.thumbnail-style.thumbnail-kenburn h3 a:focus,
.wrapper-3 .thumbnail-style.thumbnail-kenburn h3 a:hover,
.wrapper-3 .thumbnail-style.thumbnail-kenburn h3 a:focus {
	color: #888;
	text-decoration: none;
}
.thumbnail-style .thumbnail-img {
	position: relative;
	margin-bottom: 11px;
}
.thumbnail-style a.btn-more {
	right: -10px;
	bottom: 10px;
	color: #fff;
	padding: 1px 6px;
	position: absolute;
	background: #72c02c;
	display: inline-block;
}
.thumbnail-style a.btn-more:hover {
	text-decoration: none;
	box-shadow: 0 0 0 2px #5fb611;
}
.thumbnail-style:hover a.btn-more {
	right: 10px;
}
.thumbnail-kenburn img {
	left: 10px;
	margin-left: -10px;
	position: relative;
	transition: all 0.8s ease-in-out;
}
.thumbnail-kenburn:hover img {
	transform: scale(1.2) rotate(2deg);
}
.thumbnail-kenburn p {
  font-size: 16px;
}
.posts .dl-horizontal a {
	color: #555;
}
.posts .dl-horizontal {
	margin-bottom: 15px;
	overflow: hidden;
}
.posts .dl-horizontal dt {
	width: 60px;
	float: left;
}
.posts .dl-horizontal dt img {
	width: 60px;
	height: 60px;
	padding: 2px;
	margin-top: 2px;
	border: solid 1px #ddd;
}
.posts .dl-horizontal dd {
	margin-left: 70px;
}
.posts .dl-horizontal dd p {
	margin: 0;
}
.posts .dl-horizontal dd a {
	font-size: 14px;
	line-height: 16px !important;
}
.posts .dl-horizontal dd a:hover {
	color: #72c02c;
	text-decoration: none;
}
.posts .dl-horizontal:hover dt img,
.posts .dl-horizontal:hover dd a {
	color: #72c02c;
	border-color: #72c02c !important;
	transition: all 0.2s ease-in-out;
}
.privacy a {
	text-decoration: underline;
}
.privacy a:hover {
	color: #72c02c;
	text-decoration: none;
}
.title-box {
	font-size: 16px;
	text-align: center;
	padding: 10px 0 30px;
	border-bottom: solid 1px #eee;
}
.title-box .title-box-text {
	color: #555;
	display: block;
	font-size: 35px;
	font-weight: 200;
}
@media (max-width: 768px) {
	.title-box .title-box-text {
		font-size: 26px;
		line-height: 34px;
		margin-bottom: 10px;
	}
	.title-box p {
		font-size: 15px;
	}
}
.bs-glyphicons {
	padding-left: 0;
	list-style: none;
	overflow: hidden;
	padding-bottom: 1px;
	margin-bottom: 20px;
}
.bs-glyphicons li {
	width: 25%;
	float: left;
	height: 115px;
	padding: 10px;
	font-size: 11px;
	line-height: 1.4;
	text-align: center;
	margin: 0 -1px -1px 0;
	border: 1px solid #ddd;
}
.bs-glyphicons .glyphicon {
	display: block;
	font-size: 18px;
	margin: 5px auto 10px;
}
.bs-glyphicons li:hover {
	color: #72c02c;
	background-color: #fafafa;
}
@media (min-width: 768px) {
	.bs-glyphicons li {
	  width: 12.5%;
	}
}
.demo-pro-glyphicons li {
	width: 170px;
	height: 100px;
	padding: 20px 10px 30px;
}
.demo-pro-glyphicons span {
	height: 40px;
	display: block;
}
.demo-pro-glyphicons span:before {
	color: #555;
	padding: 10px 62px;
}
.demo-pro-glyphicons span.glyphicons:before {
	font-size: 22px;
}
.demo-pro-glyphicons span.halflings {
	height: 30px;
}
.demo-pro-glyphicons span.halflings:before {
	padding: 10px 70px;
}
.map {
	width: 100%;
	height: 350px;
	border-top: solid 1px #eee;
	border-bottom: solid 1px #eee;
}
.map-box {
	height: 250px;
}
.map-box-space {
	margin-top: 15px;
}
.map-box-space1 {
	margin-top: 7px;
}
.title-box-v2 {
	text-align: center;
	margin-bottom: 40px;
}
.title-box-v2 h2 {
  font-size: 25px;
  text-transform: uppercase;
}
.title-box-v2 p {
	font-size: 13px;
}
.no-space {
	padding: 0;
}
.no-space-left {
	padding-left: 0;
	padding-right: 15px !important;
}
.no-space-right {
	padding-right: 0;
	padding-left: 15px !important;
}
.list-style-v1 li {
	margin-bottom: 7px;
}
.list-style-v1 i {
	margin-right: 5px;
}
.social-contacts li {
	padding: 5px 0;
	border-top: 1px solid #ddd;
}
.social-contacts li:first-child {
	border-top: none;
}
.social-contacts li i {
	color: #777;
	font-size: 13px;
	min-width: 13px;
	margin-right: 12px;
	text-align: center;
}
.status-bar li {
	padding-left: 9px;
	border-left: 1px solid #eee;
}
.status-bar li:first-child {
	border-left: none;
}
.status-bar li i {
	color: #72c02c;
	margin-right: 5px;
}
.status-bar li a:hover {
	text-decoration: none;
}
.status-bar-in {
	padding: 10px;
	position: relative;
	background: #f0f0f0;
}
.status-bar-in:after {
	width: 0;
	height: 0;
	top: -10px;
	left: 50px;
	content: " ";
	display: block;
	position: absolute;
	border-bottom: 11px solid #f0f0f0;
	border-left: 11px solid transparent;
	border-right: 11px solid transparent;
	border-left-style: inset; /*FF fixes*/
	border-right-style: inset; /*FF fixes*/
}
.share-list {
	margin-bottom: 0;
}
.share-list li {
	padding-left: 20px;
}
.share-list li:first-child {
	padding-left: 0;
}
.share-list li i {
	color: #72c02c;
	margin-right: 5px;
}
.testimonials-bs .item {
  margin-bottom: 30px;
}
.testimonials-bs .carousel-arrow {
  text-align: center;
}
.testimonials-bs .carousel-control-v2 i {
	color: #888;
	width: 30px;
	height: 30px;
	padding: 2px;
	font-size: 22px;
	display: inline-block;
	border: 2px solid #888;
}
.testimonials-bs .carousel-control-v2 i:hover {
  color: #72c02c;
  border-color: #72c02c;
  text-decoration: none;
  transition: all 0.3s ease-in-out;
}
.testimonials-bs .carousel-control-v2.left,
.testimonials-bs .carousel-control-v2.right {
  text-decoration: none;
  background-image: none;
}
.team-v1 li > h3 {
	color: #555;
}
i.contact-box-btn {
  right: 0;
  top: 35px;
  color: #fff;
  cursor: pointer;
  z-index: 1200;
  position: fixed;
  background: #ff0000;
  border-radius: 10px 0 0 10px !important;
}
@media(max-width: 991px) {
  i.contact-box-btn {
    padding: 8px;
    font-size: 22px;
  }
}
@media(min-width: 992px) {
  i.contact-box-btn {
    padding: 12px;
    font-size: 36px;
  }
}
i.contact-box-btn:hover {
  background:#222;
}
i.contact-box-btn-option {
  top: 38px;
  background: #9097a0;
}
i.contact-box-btn-option:hover {
  background: #707985;
}
.contact-box {
  right: 0;
  top: 37px;
  color: #fff;
  width: 235px;
  display: none;
  z-index: 1210;
  position: fixed;
  background: #333;
  border-radius: 3px 0 0 3px !important;
}
.contact-box .contact-box-heading {
  color: #fff;
  font-size: 15px;
  font-weight: 200;
  margin-bottom: 10px;
}
.contact-box-logo  {
  margin-top: 10px;
  padding-top: 15px;
  border-top: solid 1px #4c5159;
}
.contact-box-logo a {
  display: block;
  text-align: center;
}
.contact-box .btn-u {
  color: #fff;
  font-weight: 200;
  border: solid 2px #555;
  border-radius: 2px !important;
}
.contact-box .btn-u.active-switcher-btn,
.contact-box .btn-u.active-switcher-btn {
  border: solid 2px #bbb;
}
.contact-box-body .serv-1 a.btn-u:hover, .contact-box-body .serv-1 a.btn-u:focus {
  border: solid 2px #6ad2b1;
}
.contact-box-body .serv-2 a.btn-u:hover, .contact-box-body .serv-2 a.btn-u:focus {
  border: solid 2px #fab74f;
}
.contact-box-body .serv-3 a.btn-u:hover, .contact-box-body .serv-3 a.btn-u:focus {
  border: solid 2px #5471b5;
}
.contact-box-body .serv-4 a.btn-u:hover, .contact-box-body .serv-4 a.btn-u:focus {
  border: solid 2px #ff3f40;
}
.contact-box-header {
  background: #252525;
  padding: 9px 10px 5px 15px;
  border-bottom: solid 1px #222;
  border-radius: 3px 0 0 0 !important;  
}
.contact-box-header .contact-box-heading {
  margin: 0;
  font-size: 16px;
  text-transform: uppercase;
}
.contact-box-header .theme-close {
  top: 9px;
  right: 6px;
  position: absolute;
}
.contact-box-header .theme-close i {
  color: #999;
  padding: 5px;
  cursor: pointer;
  font-size: 22px;
}
.contact-box-header .theme-close i:hover {
  color: #fff;
 }
.contact-box-body .no-col-space {
  padding: 0 10px;
  text-align: center;
}
.contact-box-body hr {
  margin: 11px 0;
  border-color: #454545;
}
.contact-box-body .no-col-space .col-xs-6 {
  padding-left: 5px;
  padding-right: 5px;
}
.contact-box-body {
  padding: 15px 10px 15px 15px;
}
.contact-box li {
  width: 26px;
  height: 26px;
  cursor: pointer;
  background: #c00;
  margin: 0 6px 6px 0;
  display: inline-block;
  border-radius: 10% !important;
  transition: all 0.2s ease-in-out;
}
.contact-box li.last {
  margin-right: 0;
}
.contact-box li:hover,
.contact-box li.theme-active {
  border: solid 2px #fff;
  transform: scale(1.1);
}
.contact-box li.theme-default {
  background: #72c02c;
}
.contact-box li.theme-blue {
  background: #3498db;
}
.contact-box li.theme-orange {
  background: #e67e22;
}
.contact-box li.theme-red {
  background: #e74c3c;
}
.contact-box li.theme-light {
  background: #ecf0f1;
}
.contact-box li.theme-purple {
  background: #9b6bcc;
}
.contact-box li.theme-aqua {
  background: #27d7e7;
}
.contact-box li.theme-brown {
  background: #9c8061;
}
.contact-box li.theme-dark-blue {
  background: #4765a0;
}
.contact-box li.theme-light-green {
  background: #79d5b3;
}
.contact-box li.theme-dark-red {
  background: #a10f2b;
}
.contact-box li.theme-teal {
  background: #18ba9b;
}
body.header-fixed-space-default {
	 padding-top: 81px;
}
@media (max-width: 991px) {
	 body.header-fixed-space-default {
		  padding-top: 0;
	 }
}
.header .topbar {
	 z-index: 12;
	 padding: 8px 0;
	 position: relative;
}
@media (max-width: 991px) {
	 .header .topbar {
		  margin-bottom: 20px;
	 }
}
.header .topbar ul.loginbar {
	 margin: 0;
}
.header .topbar ul.loginbar > li {
	 display: inline;
	 list-style: none;
	 position: relative;
	 padding-bottom: 15px;
}
.header .topbar ul.loginbar > li > a,
.header .topbar ul.loginbar > li > a:hover {
	 color: #7c8082;
	 font-size: 12px;
	 text-transform: uppercase;
}
.header .topbar ul.loginbar li i.fa {
	 color: #bbb;
}
.header .topbar ul.loginbar li.topbar-devider {
	 top: -1px;
	 padding: 0;
	 font-size: 10px;
	 position: relative;
	 margin: 0 9px 0 5px;
	 font-family: Tahoma;
	 border-right: solid 1px #bbb;
}
.header .topbar ul.languages {
	 top: 25px;
	 left: -5px;
	 display: none;
	 padding: 4px 0;
	 padding-left: 0;
	 list-style: none;
	 min-width: 100px;
	 position: absolute;
	 background: #f0f0f0;
}
.header .topbar li:hover ul.languages {
	 display: block;
}
.header .topbar ul.languages:after {
	 top: -4px;
	 width: 0;
	 height: 0;
	 left: 8px;
	 content: " ";
	 display: block;
	 position: absolute;
	 border-bottom: 6px solid #f0f0f0;
	 border-left: 6px solid transparent;
	 border-right: 6px solid transparent;
	 border-left-style: inset; /*FF fixes*/
	 border-right-style: inset; /*FF fixes*/
}
.header .topbar ul.languages li a {
	 color: #555;
	 display: block;
	 padding: 2px 12px;
	 margin-bottom: 1px;
	 text-transform: uppercase;
}
.header .topbar ul.languages li.active a i {
	 color: #999;
	 float: right;
	 margin-top: 2px;
}
.header .topbar ul.languages li a:hover,
.header .topbar ul.languages li.active a {
	 background: #fafafa;
}
.header .topbar ul.languages li a:hover {
	 text-decoration: none;
}
.header.no-topbar .navbar-toggle {
	 margin-top: 25px;
}
.header {
	 position: relative;
	 border-bottom: solid 2px #eee;
}
.header > .container {
	 display: table;
	 margin-bottom: -40px;
}
@media (max-width: 768px) {
	 .header > .container {
		  width: 100%;
	 }
}
@media (max-width: 991px) {
	 .header > .container {
		  margin-bottom: 0;
	 }
}
.header .logo {
 	height: 100%;
	 min-height: 40px;
	 min-width: 100px;
	 display: table-cell;
	 vertical-align: middle;
}
.header .logo img {
	 z-index: 1;
	 margin: 20px 0;
	 position: relative;
}
@media (max-width: 1200px) {
	 .header img.big-logo {
	  	width: 120px;
		  height: auto;
	 }
}
.header .navbar-toggle {
	 border-color: #5fb611;
}
@media (max-width: 991px) {
	 .header .navbar-toggle {
		  margin-right: 0;
	 }
}
.header .navbar-toggle .fa {
	 color: #fff;
	 font-size: 19px;
}
.header .navbar-toggle,
.header .navbar-toggle:hover,
.header .navbar-toggle:focus {
 	background: #72c02c;
 	padding: 6px 10px 2px;
}
.header .navbar-toggle:hover {
	 background: #5fb611 !important;
}
.header .navbar-collapse {
	 position: relative;
}
@media (max-width: 991px) {
  .header .navbar-collapse,
	 .header .navbar-collapse .container {
		  padding-left: 0;
		  padding-right: 0;
	 }
}
.header .navbar-nav > li > a {
 	color: #687074;
	 font-size: 15px;
	 font-weight:400;
	 text-transform: uppercase;
}
.header .navbar-nav > .active > a {
	 color: #72c02c;
}
@media (min-width: 992px) {
	 .header .navbar-nav {
		  float: right;
	 }
}
@media (max-width: 991px) {
  .header .navbar-header {
    float: none;
  }
  .header .navbar-toggle {
    display: block;
  }
  .header .navbar-collapse.collapse {
    display: none !important;
  }
  .header .navbar-collapse.collapse.in {
    display: block !important;
  		overflow-y: auto !important;
  }
  .header .navbar-nav {
    margin: 0 0 5px;
    float: none !important;
  }
  .header .navbar-nav > li {
    float: none;
  }
  .header .navbar-nav > li > a {
    padding-top: 30px;
    padding-bottom: 40px;
  }
  .header .dropdown-menu.pull-right {
		  float: none !important;
	 }
	 .header .navbar-nav .open .dropdown-menu {
		  border: 0;
		  float: none;
		  width: auto;
		  margin-top: 0;
		  position: static;
		  box-shadow: none;
		  background-color: transparent;
	 }
	 .header .navbar-nav .open > a,
	 .header .navbar-nav .open > a:hover,
	 .header .navbar-nav .open > a:focus {
		  border-bottom-color: #eee;
	 }
	 .header .navbar-nav .open .dropdown-menu > li > a,
	 .header .navbar-nav .open .dropdown-menu .dropdown-header {
		  padding: 5px 15px 5px 25px;
	 }
	 .header .navbar-nav .open .dropdown-menu > li > a {
		  line-height: 20px;
	 }
	 .header .navbar-nav .open .dropdown-menu > li > a:hover,
	 .header .navbar-nav .open .dropdown-menu > li > a:focus {
		  background-image: none;
	 }
	 .header .navbar-nav .open .dropdown-menu > li > a {
		  color: #777;
	 }
	 .header .navbar-nav .open .dropdown-menu > li > a:hover,
	 .header .navbar-nav .open .dropdown-menu > li > a:focus {
		  color: #333;
		  background-color: transparent;
	 }
	 .header .navbar-nav .open .dropdown-menu > .active > a,
	 .header .navbar-nav .open .dropdown-menu > .active > a:hover,
	 .header .navbar-nav .open .dropdown-menu > .active > a:focus {
		  color: #555;
		  background-color: #e7e7e7;
	 }
	 .header .navbar-nav .open .dropdown-menu > .disabled > a,
	 .header .navbar-nav .open .dropdown-menu > .disabled > a:hover,
	 .header .navbar-nav .open .dropdown-menu > .disabled > a:focus {
		  color: #ccc;
		  background-color: transparent;
	 }
	 .header .dropdown-menu.no-bottom-space {
		  padding-bottom: 0;
	 }
	 .header .navbar-nav > li > a {
		  font-size: 14px;
		  padding: 9px 10px;
	 }
	 .header .navbar-nav > li a {
		  border-bottom: solid 1px #eee;
	 }
	 .header .navbar-nav > li > a:focus {
		  background: none;
	 }
	 .header .navbar-nav > li > a:hover {
		  color: #72c02c;
	 }
 	.header .navbar-nav > .active > a,
	 .header .navbar-nav > .active > a:hover,
	 .header .navbar-nav > .active > a:focus {
		  background: #72c02c;
		  color: #fff !important;
	 }
	 .header .dropdown .dropdown-submenu > a {
		  font-size: 13px;
		  color: #555 !important;
		  text-transform: uppercase;
	 }
}
@media (min-width: 992px) {
	 .header .navbar-collapse {
		  padding: 0;
	 }
	 .header .container > .navbar-header,
	 .header .container-fluid > .navbar-header,
	 .header .container > .navbar-collapse,
	 .header .container-fluid > .navbar-collapse {
		  margin-bottom: -10px;
	 }
	 .header .navbar-nav {
		  position: relative;
	 }
  .header .navbar-nav > li > a {
		  bottom: -2px;
		  position: relative;
		  padding: 9px 30px 9px 20px;
	 }
	 .header .navbar-nav > li > a,
	 .header .navbar-nav > li > a:focus {
		  border-bottom: solid 2px transparent;
	 }
	 .header .navbar-nav > li > a:hover,
	 .header .navbar-nav > .active > a {
		  bottom: -2px;
		  position: relative;
		  border-bottom: solid 2px #72c02c;
	 }
	 .header .navbar-nav > li > a,
	 .header .navbar-nav > li > a:hover,
	 .header .navbar-nav > li > a:focus,
	 .header .navbar-nav > .active > a,
	 .header .navbar-nav > .active > a:hover,
	 .header .navbar-nav > .active > a:focus {
		  background: none;
	 }
 	.header .navbar-nav > .open > a,
	 .header .navbar-nav > .open > a:hover,
	 .header .navbar-nav > .open > a:focus {
		  color: #72c02c;
	 }
	 .header .navbar-nav > li:hover > a {
    color: #72c02c;
  }
	 .header .dropdown-menu {
		  padding: 0;
		  border: none;
		  min-width: 200px;
		  border-radius: 0;
		  z-index: 9999 !important;
		  border-top: solid 2px #72c02c;
		  border-bottom: solid 2px #687074;
	 }
	 .header .dropdown-menu li a {
		  color: #687074;
		  font-size: 15px;
		  font-weight: 400;
		  padding: 6px 15px;
		  border-bottom: solid 1px #eee;
	 }
	 .header .dropdown-menu .active > a,
	 .header .dropdown-menu li > a:hover {
		  color: #687074;
		  filter: none !important;
		  background: #eee !important;
		  transition: all 0.1s ease-in-out;
	 }
	 .header .dropdown-menu li > a:focus {
		  background: none;
		  filter: none !important;
	 }
 	.header .navbar-nav > li.dropdown:hover > .dropdown-menu {
	  	display: block;
	 }
	 .header .open > .dropdown-menu {
		  display: none;
	 }
	 .header .navbar-nav .search-open {
		  width: 330px;
	 }
}
.header .dropdown-submenu {
  position: relative;
}
.header .dropdown > a:after,
.header .dropdown-submenu > a:after {
  top: 8px;
  right: 9px;
  font-size: 11px;
  content: "\f105";
  position: absolute;
  font-weight: normal;
  display: inline-block;
  font-family: FontAwesome;
}
@media (max-width: 991px) {
  .header .dropdown-submenu > a:after {
   	content: " ";
  }
}
.header .dropdown > a:after {
  top: 9px;
  right: 15px;
  content: "\f107";
}
.header .dropdown-submenu > .dropdown-menu {
	 top: 3px;
	 left: 100%;
	 margin-top: -5px;
	 margin-left: 0px;
}
.header .dropdown-submenu > .dropdown-menu.submenu-left {
	 left: auto !important;
  right: 100%;
}
.header .dropdown-submenu:hover > .dropdown-menu {
  display: block;
}
@media (max-width: 991px) {
	 .header .dropdown-submenu > .dropdown-menu {
		  display: block;
		  margin-left: 15px;
	 }
}
.header .dropdown-submenu.pull-left {
	 float: none;
}
.header .dropdown-submenu.pull-left > .dropdown-menu {
	 left: -100%;
	 margin-left: 10px;
}
.header .dropdown-menu li [class^="fa-"],
.header .dropdown-menu li [class*=" fa-"] {
	 left: -3px;
	 width: 1.25em;
	 margin-right: 1px;
	 position: relative;
	 text-align: center;
	 display: inline-block;
}
.header .dropdown-menu li [class^="fa-"].fa-lg,
.header .dropdown-menu li [class*=" fa-"].fa-lg {
	 width: 1.5625em;
}
.header .nav > li > .search {
	 color: #aaa;
	 cursor: pointer;
	 min-width: 35px;
	 font-size: 15px;
	 text-align: center;
	 background: #f7f7f7;
	 padding: 11px 0 12px;
	 display: inline-block;
	 border-bottom: solid 2px #aaa;
}
@media (min-width: 992px) {
	 .header .nav > li > .search {
		  bottom: -2px;
		  position: relative;
	 }
}
.header .nav > li > .search:hover {
	 color: #72c02c;
	 background: #f7f7f7;
	 border-bottom-color: #72c02c;
}
.header .nav .search-open {
	 right: 0;
	 top: 40px;
	 display: none;
	 padding: 14px;
	 position: absolute;
	 background: #fcfcfc;
	 border-top: solid 2px #eee;
	 box-shadow: 0 1px 3px #ddd;
}
.header .nav .search-open form {
	 margin: 0;
}
@media (min-width: 767px) and (max-width: 991px) {
	 .header .navbar-toggle {
		  margin-right: 0;
	 }
	 .header .nav .search-open {
		  width: 93%;
	 }
}
@media (max-width: 991px) {
	 .header .nav > li > .search {
		  color: #999;
		  width: 35px;
		  height: 34px;
		  text-align: left;
		  background: #eee;
		  line-height: 12px;
		  text-align: center;
		  margin: 5px 10px 0;
		  border-bottom: none;
	 }
	 .header .nav > li > .search:hover {
		  background: #5fb611;
		  color: #fff !important;
	 }
 	.header .nav .search-open {
		  top: 5px;
		  padding: 0;
		  left: 48px;
		  border-top: none;
		  box-shadow: none;
		  background: none;
		  margin-right: 10px;
	 }
}
.header .mega-menu .nav,
.header .mega-menu .dropup,
.header .mega-menu .dropdown,
.header .mega-menu .collapse {
  position: static;
}
.header .mega-menu .navbar-inner,
.header .mega-menu .container {
  position: relative;
}
.header .mega-menu .dropdown-menu {
  left: auto;
}
.header .mega-menu .dropdown-menu > li {
  display: block;
}
.header .mega-menu .dropdown-submenu .dropdown-menu {
  left: 100%;
}
.header .mega-menu .nav.pull-right .dropdown-menu {
  right: 0;
}
.header .mega-menu .mega-menu-content {
  *zoom: 1;
  padding: 4px 15px;
}
.header .mega-menu .mega-menu-content:before,
.header .mega-menu .mega-menu-content:after {
  content: "";
  display: table;
  line-height: 0;
}
.header .mega-menu .mega-menu-content:after {
  clear: both;
}
.header .mega-menu .nav > li > .dropdown-menu:after,
.header .mega-menu .nav > li > .dropdown-menu:before {
  display: none;
}
.header .mega-menu .dropdown.mega-menu-fullwidth .dropdown-menu {
  left: 0;
  right: 0;
  overflow: hidden;
}
@media (min-width: 992px) {
	 .header .mega-menu .mega-menu-content.content-two-col {
	   min-width: 596px;
	 }
	 .header .mega-menu .mega-menu-content.content-three-col {
	   min-width: 864px;
	 }
}
.header .mega-menu .dropdown.mega-menu-left .dropdown-menu {
	 left: auto;
	 overflow: hidden;
}
.header .mega-menu .dropdown.mega-menu-right .dropdown-menu,
.header .mega-menu .dropdown.mega-menu-two-col-right .dropdown-menu {
	 right: 0;
	 overflow: hidden;
}
@media (min-width: 992px) {
	 .header .mega-menu .equal-height {
	   display: flex;
	 }
	 .header .mega-menu .equal-height-in {
	   display: flex;
	 }
}
.header .mega-menu .equal-height-list h3 {
	 font-size: 15px;
	 font-weight: 400;
	 padding: 0 10px 0 13px;
	 text-transform: uppercase;
}
@media (min-width: 992px) {
	 .header .mega-menu .big-screen-space {
		  margin-bottom: 20px;
	 }
}
@media (min-width: 992px) {
  .header .mega-menu .equal-height-in {
    padding: 20px 0;
    border-left: 1px solid #eee;
  }
  .header .mega-menu .equal-height-in:first-child {
    border-left: none;
    margin-left: -1px;
  }
  .header .mega-menu .equal-height-list {
    width: 100%;
  }
  .header .mega-menu .equal-height-list li a {
    display: block;
    margin-bottom: 1px;
    position: relative;
    border-bottom: none;
    padding: 5px 10px 5px 15px;
  }
  .header .mega-menu .equal-height-list a:hover {
  	 text-decoration: none;
  }
  .header .mega-menu .mega-menu-content li a {
    padding: 5px 10px 5px 30px;
  }
  .header .mega-menu .mega-menu-content li a:after {
    top: 7px;
    left: 15px;
    font-size: 11px;
    content: "\f105";
    position: absolute;
    font-weight: normal;
    display: inline-block;
    font-family: FontAwesome;
  }
  .header .mega-menu .disable-icons li a {
    padding: 5px 10px 5px 15px;
  }
  .header .mega-menu .disable-icons li a:after {
    display: none;
  }
}
@media (max-width: 991px) {
  .header .mega-menu .equal-height-list h3 {
		  color: #555;
		  margin: 0 0 5px;
		  font-size: 13px;
		  font-weight: 400;
		  padding: 6px 25px 5px;
		  border-bottom: solid 1px #eee;
	 }
	 .header .mega-menu .equal-height-list li a {
		  color: #687074;
		  display: block;
		  font-size: 13px;
		  font-weight: 400;
		  margin-left: 15px;
		  padding: 6px 25px;
		  border-bottom: solid 1px #eee;
	 }
	 .header .mega-menu .equal-height-list > h3.active,
	 .header .mega-menu .equal-height-list > .active > a,
	 .header .mega-menu .equal-height-list > .active > a:hover,
	 .header .mega-menu .equal-height-list > .active > a:focus {
		  color: #555;
		  background-color: #e7e7e7;
	 }
 	.header .mega-menu .equal-height-list li a:hover {
		  color: #333;
		  text-decoration: none;
	 }
}
.header.header-dark {
	 background: #2d2d2d;
	 border-bottom-color: #555;
}
.header.header-dark .topbar ul.languages {
	 background: #444;
}
.header.header-dark .topbar ul.loginbar li.topbar-devider {
	 border-right-color: #666;
}
.header.header-dark .topbar ul.languages li a {
	 color: #bbb;
}
.header.header-dark .topbar ul.languages:after {
	 border-bottom-color: #444;
}
.header.header-dark .topbar ul.languages li a:hover,
.header.header-dark .topbar ul.languages li.active a {
	 color: #bbb;
	 background: #555;
}
.header.header-dark .navbar-nav > li > a {
	 color: #ddd;
}
.header.header-dark .navbar-nav > .open > a,
.header.header-dark .navbar-nav > .open > a:hover,
.header.header-dark .navbar-nav > .open > a:focus {
 	background: inherit;
}
@media (max-width: 991px) {
	 .header.header-dark .navbar-nav > li a {
		  border-bottom-color: #666;
	 }
 	.header.header-dark .navbar-nav .dropdown > a:hover {
		  color: #72c02c;
		  background: inherit;
	 }
	 .header.header-dark .navbar-nav .open > a,
	 .header.header-dark .navbar-nav .open > a:hover,
	 .header.header-dark .navbar-nav .open > a:focus {
		  border-bottom-color: #666;
	 }
	 .header.header-dark .navbar-nav .open .dropdown-menu > li > a {
		  color: #bbb;
	 }
	 .header.header-dark .navbar-nav .open .dropdown-menu > li > a:hover,
	 .header.header-dark .navbar-nav .open .dropdown-menu > li > a:focus {
		  color: #fff !important;
	 }
	 .header.header-dark .navbar-nav .open .dropdown-menu > .active > a,
	 .header.header-dark .navbar-nav .open .dropdown-menu > .active > a:hover,
	 .header.header-dark .navbar-nav .open .dropdown-menu > .active > a:focus {
		  color: #bbb;
		  background: #444;
	 }
	 .header.header-dark .dropdown .dropdown-submenu > a {
		  color: #bbb !important;
	 }
}
@media (min-width: 992px) {
	 .header.header-dark .navbar-nav > li > a:hover,
	 .header.header-dark .navbar-nav > .active > a {
		  color: #72c02c !important;
	 }
	 .header.header-dark .dropdown-menu {
		  background: #2d2d2d;
	 }
	 .header.header-dark .dropdown-menu li > a {
		  color: #bbb;
		  border-bottom-color: #666;
	 }
	 .header.header-dark .dropdown-menu .active > a,
	 .header.header-dark .dropdown-menu li > a:hover {
		  background: #202020 !important;
	 }
}
.header.header-dark  .nav > li > .search {
	 background: #444;
}
.header.header-dark  .search-open {
 	background: #2d2d2d;
 	border-top-color: #555;
	 box-shadow: 0 1px 3px #888;
}
.header.header-dark .input-group .form-control {
	 color: #bbb;
	 background: #555;
	 border-color: #999;
	 box-shadow: 0 0 2px #555;
}
.header.header-dark .mega-menu .equal-height-list h3 {
	 color: #eee;
}
@media (max-width: 991px) {
	 .header.header-dark .mega-menu .equal-height-list li a {
		  color: #bbb;
		  border-bottom-color: #666;
	 }
	 .header.header-dark .mega-menu .equal-height-list li a:focus,
	 .header.header-dark .mega-menu .equal-height-list li a:hover {
		  color: #fff;
	 }
	 .header.header-dark .mega-menu .equal-height-list h3 {
		  border-bottom-color: #666;
	 }
}
@media (min-width: 992px) {
	 .header.header-dark .mega-menu .equal-height-in {
		  border-left-color: #666;
	 }
}
.header-fixed .header {
	 height: auto;
}
.header-fixed .header.header-sticky {
	 margin-top: 0;
	 transition: all 0.2s ease-in-out;
	 position: fixed;
	 top: 0;
	 left: 0;
	 width: 100%;
}
.header-fixed .header.header-fixed-shrink {
	 z-index: 10100;
	 border-bottom: none;
	 box-shadow: 0 0 3px #bbb;
	 background: rgba(255,255,255, 0.96);
	 transition: all 0.3s ease-in-out;
}
@media (max-width: 991px) {
	 .header-fixed .header.header-sticky,
	 .header-fixed .header.header-fixed-shrink {
		  position: relative;
	 }
}
@media (min-width: 992px) {
	 .header-fixed .header.header-fixed-shrink {
		  margin-top: -35px;
	 }
	 .header-fixed .header.header-fixed-shrink > .container {
		  margin-bottom: -46px;
	 }
	 .header-fixed .header.header-fixed-shrink .logo img {
		  max-width: 105px;
		  margin: 45px 0 -4px;
	 }
	 .header-fixed .header.header-fixed-shrink .navbar-nav > li > a {
		  padding-top: 19px;
		  padding-bottom: 19px;
	 }
	 .header-fixed .header.header-fixed-shrink .navbar-nav > li > a:hover,
	 .header-fixed .header.header-fixed-shrink .navbar-nav > .active > a {
		  border-bottom: none;
	 }
	 .header-fixed .header.header-fixed-shrink .dropdown > a:after {
		  top: 20px;
	 }
	 .header-fixed .header.header-fixed-shrink .nav > li > .search {
		  color: #777;
		  padding: 20px 0 22px;
		  background: inherit;
		  border-bottom: none;
	 }
	 .header-fixed .header.header-fixed-shrink .nav .search-open {
		  top: 60px;
	 }
}
.boxed-layout.header-fixed .header.header-sticky {
	left: inherit;
	width: inherit;
}
.blog-topbar {
	 background: #111;
}
.blog-topbar .container,
.blog-topbar .container-fluid {
  padding-top: 5px;
  padding-bottom: 5px;
}
.topbar-list {
	 padding-left: 0;
	 list-style: none;
	 margin-bottom: 0;
	 float: left;
}
.topbar-list > li {
	 color: #eee;
	 padding: 7px 8px 7px 8px;
	 position: relative;
	 letter-spacing: 1px;
	 display: inline-block;
	 text-transform: uppercase;
}
@media(max-width: 767px) {
	 .topbar-list > li {
	   padding: 7px 8px 7px 0px;
	 }
}
/*
.topbar-list > li:before {
	 top: 12px;
	 left: -3px;
	 width: 1px;
	 height: 11px;
	 content: ' ';
	 background: #eee;
	 position: absolute;
}
*/
.topbar-list li:last-child {
	 padding-right: 0;
}
.topbar-list li a {
	 color: #eee;
}
.topbar-list li a:hover {
	 color: #fff;
	 text-decoration: none;
}
.topbar-log_reg li.home:before {
	 display: none;
}
.topbar-list .topbar-dropdown {
	 top: 23px;
	 left: -3px;
  z-index: 999;
	 display: none;
	 padding: 0;
	 list-style: none;
	 min-width: 125px;
	 background: #151515;
	 position: absolute;
}
.topbar-list li:hover .topbar-dropdown {
  display: block;
}
.topbar-list .topbar-dropdown li a {
	 color: #eee;
	 display: block;
	 font-size: 13px;
	 padding: 8px 15px;
	 margin-bottom: 1px;
	 text-transform: uppercase;
}
.topbar-list .topbar-dropdown li a:hover,
.topbar-list .topbar-dropdown li.active a {
	 color: #fff;
}
.topbar-list .topbar-dropdown li a:hover {
	 text-decoration: none;
}
.topbar-list .topbar-submenu {
  position: relative;
}
.topbar-list .topbar-submenu > a:after {
  top: 8px;
  right: 9px;
  font-size: 11px;
  content: "\f105";
  position: absolute;
  font-weight: normal;
  display: inline-block;
  font-family: FontAwesome;
}
.topbar-list .topbar-submenu:hover > .topbar-submenu-in {
  display: block;
}
.topbar-list .topbar-submenu > .topbar-submenu-in {
	 top: 0;
	 left: 100%;
	 float: left;
	 z-index: 1000;
	 display: none;
	 padding: 7px 0;
	 font-size: 10px;
	 min-width: 125px;
	 text-align: left;
	 list-style: none;
  background: #151515;
  position: absolute;
	 text-transform: uppercase;
}
.topbar-list .topbar-submenu li:before {
	 display: none;
}
@media (min-width: 768px) {
  .topbar-time {
  	 color: #fff;
  	 text-transform: uppercase;
  	 letter-spacing: 1px;
    padding: 7px 12px 7px 0;
    float: left;
  }
}
@media (max-width: 767px) {
  .topbar-time {
    display: none;
  }
}
.topbar-toggler {
	 color: #fff;
	 cursor: pointer;
	 font-size: 16px;
	 float: left;
	 display: none;
	 padding-top: 1px;
}
@media (max-width: 768px) {
	 .topbar-toggler {
		  display: block;
	 }
	 .topbar-menu li {
		  display: block;
	 }
	 .topbar-menu li:before {
		  display: none;
	 }
	 .topbar-menu strong {
		  font-weight: normal;
		  opacity: 0.5;
	 }
	 .topbar-list .topbar-dropdown {
    z-index: 999;
    background: #333;
    position: absolute;
    min-width: auto;
		  padding: 7px 0 0;
	 }
	 .topbar-list .topbar-submenu > .topbar-submenu-in {
	  float: none;
    top: 0;
    left: 10px;
    position: relative;
    min-width: auto;
    display: block;
    padding: 7px 0 0;
	 }
	 .topbar-list .topbar-dropdown li:last-child {
		  padding-bottom: 0;
	 }
	 .topbar-list .topbar-submenu > a:after {
		  display: none;
	 }
}
@media (min-width: 769px) {
	 .topbar-menu {
		  display: block !important;
	 }
}
.blog-topbar .search-btn {
	 color: #fff;
	 cursor: pointer;
	 font-size: 14px;
	 padding: 10px 0 0 10px;
}
.blog-topbar .search-btn:hover {
	 color: #bbb;
}
.topbar-search-block {
	 top: 0;
  left: 0;
	 width: 100%;
	 display: none;
  background: #333;
}
.topbar-search-block .container {
  padding-top: 0;
  padding-bottom: 0;
}
.topbar-search-block form {
	 position: relative;
}
.topbar-search-block .search-close {
  top: 15px;
  right: 0;
  color: #fff;
  cursor: pointer;
  position: absolute;
}
.topbar-search-block .form-control {
  padding: 0;
  color: #fff;
  border: none;
  min-height: 50px;
  font-size: 22px;
  font-weight: 400;
  background: #333;
}
.topbar-search-block .form-control:focus {
 	box-shadow: none;
}
.topbar-search-block .form-control::-moz-placeholder {
  color: #eee;
  font-weight: 200;
}
.topbar-search-block .form-control:-ms-input-placeholder {
  color: #eee;
  font-weight: 200;
}
.topbar-search-block .form-control::-webkit-input-placeholder {
  color: #eee;
  font-weight: 200;
}
.header-v8 {
	 background: #fff;
	 position: relative;
}
@media (max-width: 991px) {
	 .header-v8 {
		  position: static;
	 }
}
@media (max-width: 991px) {
	 .header-v8 .res-container:before,
	 .header-v8 .res-container:after {
		  content: " ";
		  display: table;
	 }
	 .header-v8 .res-container:after {
		  clear: both;
	 }
	 .header-v8 > .navbar > .container > .navbar-header,
	 .header-v8 > .navbar > .container > .navbar-collapse {
	  	margin-left: 0;
	  	margin-right: 0;
	 }
}
@media (min-width: 992px) {
  .header-v8 .res-container {
    padding-top: 10px;
  }
}
.header-v8 .navbar {
	 border: none;
	 margin-bottom: 0;
}
@media (max-width: 991px) {
  .header-v8 .navbar-brand {
    height: 120px;
    min-height: 100px;
    padding: 15px 5px 5px 0;
  }
  .header-v8.header-fixed-shrink .navbar-brand {
    height: 190px;
    padding: 15px 5px 15px 0;
  }
}
@media (min-width: 992px) {
  .header-v8 .navbar-brand {
    height: 201px;
    padding: 5px;
  }
  .header-v8.header-fixed-shrink .navbar-brand {
    height: 190px;
    padding: 5px 5px 8px 5px;
  }
}
.header-v8 .navbar-brand img {
	 width: auto;
	 height: auto;
	 max-width: 100%;
	 max-height: 100%;
	 display: inline-block;
	 vertical-align: middle;
}
@media (min-width: 992px) {
	 .header-v8 .navbar-nav {
		  float: right;
	 }
}
@media (max-width: 768px) {
	 .header-v8 .navbar-brand {
		  padding-left: 0;
	 }
}
.header-v8 .navbar-toggle {
	 padding: 9px 0;
	 margin: 34px 0 0;
}
.header-v8 .navbar-toggle .icon-bar {
	 height: 1px;
	 width: 30px;
	 font-size: 20px;
	 background: #333;
	 margin-bottom: 7px;
}
.header-v8 .navbar-toggle .icon-bar:last-child {
	 margin-bottom: 0;
}
.header-v8 .navbar-toggle,
.header-v8 .navbar-toggle:hover,
.header-v8 .navbar-toggle:focus {
	 background: inherit;
}
.header-v8 .navbar-collapse {
	 position: relative;
}
.header-v8 .navbar-nav > li > a {
	 color: #fff;
	 font-size: 22px;
	 letter-spacing: .05rem;
	 text-transform: uppercase;
}
.header-v8 .nav > li > a:hover,
.header-v8 .nav > li > a:focus {
	 background: inherit;
}
@media (max-width: 991px) {
  .header-v8 .navbar-header {
    float: none;
  }
  .header-v8 .navbar-toggle {
    display: block;
  }
  .header-v8 .navbar-collapse {
		  background: #fff;
		  border-color: #eee;
    border-bottom: 1px solid #eee;
  }
  .header-v8 .navbar-collapse.collapse {
    display: none !important;
  }
  .header-v8 .navbar-collapse.collapse.in {
    display: block !important;
  		overflow-y: auto !important;
  }
  .header-v8 .navbar-nav {
    margin: 10px 0;
    float: none !important;
  }
  .header-v8 .navbar-nav > li {
    float: none;
  }
  .header-v8 .navbar-nav > li > a {
    padding-top: 30px;
    padding-bottom: 40px;
  }
  .header-v8 .navbar-nav > li > a {
    color: #444;
  }
  .header-v8 .dropdown-menu.pull-right {
		  float: none !important;
	 }
	 .header-v8 .navbar-nav .open > a:focus,
	 .header-v8 .navbar-nav .open > a:hover {
		  border-color: #eee;
	 }
	 .header-v8 .navbar-nav .open .dropdown-menu {
		  border: 0;
		  float: none;
		  width: auto;
		  margin-top: 0;
		  position: static;
		  box-shadow: none;
		  background-color: transparent;
	 }
	 .header-v8 .navbar-nav .open .dropdown-menu > li > a,
	 .header-v8 .navbar-nav .open .dropdown-menu .dropdown-header {
		  padding: 5px 15px 5px 25px;
	 }
	 .header-v8 .navbar-nav .open .dropdown-menu > li > a {
		  line-height: 20px;
	 }
	 .header-v8 .navbar-nav .open .dropdown-menu > li > a:hover,
	 .header-v8 .navbar-nav .open .dropdown-menu > li > a:focus {
		  background-image: none;
	 }
	 .header-v8 .navbar-nav .open .dropdown-menu > li > a {
		  color: #777;
	 }
	 .header-v8 .navbar-nav .open .dropdown-menu > li > a:hover,
	 .header-v8 .navbar-nav .open .dropdown-menu > li > a:focus {
	  	background-color: transparent;
	 }
	 .header-v8 .navbar-nav .open .dropdown-menu > .active > a,
	 .header-v8 .navbar-nav .open .dropdown-menu > .active > a:hover,
	 .header-v8 .navbar-nav .open .dropdown-menu > .active > a:focus {
		  background-color: transparent;
	 }
	 .header-v8 .navbar-nav .open .dropdown-menu > .disabled > a,
	 .header-v8 .navbar-nav .open .dropdown-menu > .disabled > a:hover,
	 .header-v8 .navbar-nav .open .dropdown-menu > .disabled > a:focus {
		  background-color: transparent;
	 }
	 .header-v8 .dropdown-menu.no-bottom-space {
		  padding-bottom: 0;
	 }
  .header-v8 .navbar-collapse,
  .header-v8 .navbar-collapse .container {
		  padding-left: 0 !important;
		  padding-right: 0 !important;
		  margin-left: 0;
		  margin-right: 0;
	 }
	 .header-v8 .navbar-nav > li > a {
		  font-size: 14px;
		  padding: 10px 5px;
	 }
	 .header-v8 .navbar-nav > li a {
		  border-bottom: none;
		  border-top: none;
	 }
	 .header-v8 .navbar-nav > li:first-child > a {
		  border-top: transparent;
	 }
	 .header-v8 .navbar-nav > .open > a,
	 .header-v8 .navbar-nav > .open > a:hover,
	 .header-v8 .navbar-nav > .open > a:focus {
		  color: #555;
		  background: inherit;
	 }
	 .header-v8 .navbar-nav > .active > a,
	 .header-v8 .navbar-nav > .active > a:hover,
	 .header-v8 .navbar-nav > .active > a:focus {
		  background: inherit;
  }
	 .header-v8 .dropdown .dropdown-submenu > a {
		  font-size: 13px;
		  color: #555 !important;
		  text-transform: uppercase;
	 }
}
@media (min-width: 992px) {
	 .header-v8 .navbar-collapse {
		  padding: 0;
	 }
	 .header-v8 .navbar {
		  min-height: 40px !important;
	 }
	 .header-v8 .container > .navbar-header,
	 .header-v8 .container-fluid > .navbar-header,
	 .header-v8 .container > .navbar-collapse,
	 .header-v8 .container-fluid > .navbar-collapse {
	 }
	 .header-v8 .navbar-nav {
		  top: 2px;
		  position: relative;
	 }
	 .header-v8 .navbar-nav > li > a {
		  padding: 20px;
		  line-height: 54px;
	 }
	 .header-v8 .navbar-nav > li:last-child > a {
		  padding-right: 0;
	 }
	 .header-v8 .navbar-nav > li.home > a {
		  padding-left: 0;
	 }
	 .header-v8 .navbar-nav > li > a,
	 .header-v8 .navbar-nav > li > a:hover,
	 .header-v8 .navbar-nav > li > a:focus,
	 .header-v8 .navbar-nav > .active > a,
	 .header-v8 .navbar-nav > .active > a:hover,
	 .header-v8 .navbar-nav > .active > a:focus {
		  color: #728885;
		  background: none;
	 }
	 .header-v8 .navbar-nav > .open > a,
	 .header-v8 .navbar-nav > .open > a:hover,
	 .header-v8 .navbar-nav > .open > a:focus {
		  color: #333;
	 }
	 .header-v8 .dropdown-menu {
	  	border: none;
		  padding: 15px 0;
		  min-width: 230px;
		  border-radius: 0;
      background-color: rgb(234, 234, 234);
		  z-index: 9999 !important;
	 }
  .header-v8 .dropdown-menu > li > a {
		  color: #777;
		  font-size: 18px;
		  font-weight: 400;
		  padding: 6px 25px;
	 }
	 .header-v8 .dropdown-menu .active > a,
	 .header-v8 .dropdown-menu li > a:hover {
		  background: inherit;
		  filter: none !important;
		  transition: all 0.1s ease-in-out;
	 }
	 .header-v8 .dropdown-menu li > a:focus {
		  background: none;
		  filter: none !important;
	 }
	 .header-v8 .navbar-nav > li.dropdown:hover > .dropdown-menu {
		  display: block;
	 }
	 .header-v8 .open > .dropdown-menu {
		  display: none;
	 }
}
.header-v8 .dropdown-submenu {
  position: relative;
}
.header-v8 .dropdown-submenu > a:after {
  top: 9px;
  right: 15px;
  font-size: 11px;
  content: "\f105";
  position: absolute;
  font-weight: normal;
  display: inline-block;
  font-family: FontAwesome;
}
@media (max-width: 991px) {
  .header-v8 .dropdown-submenu > a:after {
    content: " ";
  }
}
.header-v8 .dropdown-submenu > .dropdown-menu {
	 top: -20px;
	 left: 100%;
	 margin-left: 0px;
}
.header-v8 .dropdown-submenu > .dropdown-menu.submenu-left {
	 left: -100%;
}
.header-v8 .dropdown-submenu:hover > .dropdown-menu {
  display: block;
}
@media (max-width: 991px) {
	 .header-v8 .dropdown-submenu > .dropdown-menu {
		  display: block;
		  margin-left: 15px;
	 }
}
.header-v8 .dropdown-submenu.pull-left {
	 float: none;
}
.header-v8 .dropdown-submenu.pull-left > .dropdown-menu {
	 left: -100%;
	 margin-left: 10px;
}
.header-v8 .dropdown-menu li [class^="fa-"],
.header-v8 .dropdown-menu li [class*=" fa-"] {
 	left: -3px;
	 width: 1.25em;
	 margin-right: 1px;
	 position: relative;
	 text-align: center;
	 display: inline-block;
}
.header-v8 .dropdown-menu li [class^="fa-"].fa-lg,
.header-v8 .dropdown-menu li [class*=" fa-"].fa-lg {
	 width: 1.5625em;
}
.header-v8 .mega-menu .nav,
.header-v8 .mega-menu .dropup,
.header-v8 .mega-menu .dropdown,
.header-v8 .mega-menu .collapse {
  position: static;
}
.header-v8 .mega-menu .navbar-inner,
.header-v8 .mega-menu .container {
  position: relative;
}
.header-v8 .mega-menu .dropdown-menu {
  left: auto;
}
.header-v8 .mega-menu .dropdown-menu > li {
  display: block;
}
.header-v8 .mega-menu .dropdown-submenu .dropdown-menu {
  left: 100%;
}
.header-v8 .mega-menu .nav.pull-right .dropdown-menu {
  right: 0;
}
.header-v8 .mega-menu .mega-menu-content {
  *zoom: 1;
  padding: 0;
}
.header-v8 .mega-menu .mega-menu-content:before,
.header-v8 .mega-menu .mega-menu-content:after {
  content: "";
  display: table;
  line-height: 0;
}
.header-v8 .mega-menu .mega-menu-content:after {
  clear: both;
}
.header-v8 .mega-menu.navbar .nav > li > .dropdown-menu:after,
.header-v8 .mega-menu.navbar .nav > li > .dropdown-menu:before {
  display: none;
}
.header-v8 .mega-menu .mega-menu-fullwidth .dropdown-menu {
  left: 0;
  right: 0;
  overflow: hidden;
}
.header-v8 .mega-menu .mega-menu-fullwidth .dropdown-menu li a {
 	padding: 0;
}
.header-v8 .mega-menu .slim .dropdown-menu li a:hover {
  background-color: #fff;
}
.header-v8 .mega-menu .mega-menu-fullwidth .dropdown-menu li h2 {
	 color: #333;
	 margin: 0 0 5px;
	 font-size: 20px;
	 font-weight: bold;
	 text-transform: uppercase;
}
.header-v8 .mega-menu .mega-menu-fullwidth .dropdown-link-list {
	 padding-left: 0;
	 list-style: none;
	 margin-bottom: 0;
}
.header-v8 .mega-menu .mega-menu-fullwidth .dropdown-link-list li {
	 display: block;
}
.header-v8 .mega-menu .mega-menu-fullwidth .dropdown-link-list li a {
	 color: #777;
	 display: block;
	 padding: 8px 0 8px 10px;
	 font-size: 18px;
	 font-weight: 400;
	 border-bottom: solid 1px #eee;
}
.header-v8 .mega-menu .mega-menu-fullwidth .dropdown-link-list li a:hover {
	 text-decoration: none;
	 color: #000 !important;
   background-color: #fff;
}
@media (min-width: 768px) {
	 .header-v8 .mega-menu .mega-menu-fullwidth .mega-menu-content .row {
		  margin-right: 15px;
	 }
}
@media (max-width: 991px) {
  .header-v8 .mega-menu .mega-menu-fullwidth .dropdown-menu {
    width: auto;
  }
  .header-v8 .mega-menu .mega-menu-fullwidth .dropdown-menu > li > ul {
    display: block;
  }
  .header-v8 .mega-menu .mega-menu-fullwidth .dropdown-menu li h2 {
		  font-size: 13px;
		  padding-left: 10px;
	 }
  .header-v8 .mega-menu .mega-menu-fullwidth .dropdown-menu li a {
		  border-top: none;
	 }
	 .header-v8 .mega-menu .mega-menu-fullwidth .dropdown-link-list > li > a {
		  padding-left: 15px;
	 }
}
@media (min-width: 992px) {
	 .header-v8 .mega-menu .mega-menu-fullwidth .dropdown-menu {
		  padding-top: 25px;
		  margin-left: 15px;
		  margin-right: 15px;
		  padding-bottom: 25px;
	 }
}
.header-fixed .header-v8.header-sticky {
  top: 0;
  left: 0;
  width: 100%;
  height: auto;
  z-index: 1100;
  position: fixed;
}
@media (max-width: 991px) {
	 .header-fixed .header-v8.header-sticky {
		  position: static;
	 }
}
@media (min-width: 992px) {
	 .header-fixed .header-v8.header-fixed-shrink .blog-topbar {
		  margin-top: -40px;
	 }
 	.header-fixed .header-v8.header-fixed-shrink .navbar-brand {
	  	height: 180px;
		  min-height: 150px;
	 }
	 .header-fixed .header-v8.header-fixed-shrink .navbar-nav > li > a {
		  line-height: 30px;
	 }
	 .header-v8 .blog-topbar,
	 .header-v8 .navbar-brand,
	 .header-v8 .navbar-nav > li > a,
	 .header-fixed .header-v8.header-fixed-shrink .blog-topbar,
	 .header-fixed .header-v8.header-fixed-shrink .navbar-brand,
	 .header-fixed .header-v8.header-fixed-shrink .navbar-nav > li > a {
	 transition: all 0.3s cubic-bezier(0.5, 1, 0.7, 1);
	 }
}
.boxed-layout.header-fixed .header-v8.header-sticky {
	 left: inherit;
	 width: inherit;
}
.boxed-layout-space.header-fixed .header-v8.header-sticky {
	 top: 40px;
}
@media(max-width: 767px){
	 .boxed-layout-space.header-fixed .header-v8.header-sticky {
		  top: 0;
	 }
}
.header-v8 .dropdown-menu .active > a,
.header-v8 .dropdown-menu li > a:hover {
	 color: #72c02c;
}
.header-v8 .navbar-nav .open .dropdown-menu > li > a:hover,
.header-v8 .navbar-nav .open .dropdown-menu > li > a:focus {
	 color: #72c02c;
}
.header-v8 .navbar-nav .open .dropdown-menu > .active > a,
.header-v8 .navbar-nav .open .dropdown-menu > .active > a:hover,
.header-v8 .navbar-nav .open .dropdown-menu > .active > a:focus {
	 color: #72c02c;
}
.header-v8 .navbar-nav .open .dropdown-menu > .disabled > a,
.header-v8 .navbar-nav .open .dropdown-menu > .disabled > a:hover,
.header-v8 .navbar-nav .open .dropdown-menu > .disabled > a:focus {
 	color: #72c02c;
}
.header-v8 .navbar-nav > li > a:hover {
	 color: #72c02c;
}
.header-v8 .navbar-nav > .active > a,
.header-v8 .navbar-nav > .active > a:hover,
.header-v8 .navbar-nav > .active > a:focus {
	 color: #72c02c !important;
}
.header-v8 .mega-menu .mega-menu-fullwidth .dropdown-link-list li a:hover {
	 color: #72c02c;
}
.header-v8 .dropdown-menu .active > a {
  color: #333;
}
.header-v8 .dropdown-menu li > a:hover {
  color: #000;
}
.header-v8 .navbar-nav .open .dropdown-menu > li > a:hover,
.header-v8 .navbar-nav .open .dropdown-menu > li > a:focus {
  color: #00caca;
}
.header-v8 .navbar-nav .open .dropdown-menu > .active > a,
.header-v8 .navbar-nav .open .dropdown-menu > .active > a:hover,
.header-v8 .navbar-nav .open .dropdown-menu > .active > a:focus {
  color: #00caca;
}
.header-v8 .navbar-nav .open .dropdown-menu > .disabled > a,
.header-v8 .navbar-nav .open .dropdown-menu > .disabled > a:hover,
.header-v8 .navbar-nav .open .dropdown-menu > .disabled > a:focus {
  color: #00caca;
}
.header-v8 .navbar-nav > li > a:hover {
  color: #00caca;
}
.header-v8 .navbar-nav > .active > a,
.header-v8 .navbar-nav > .active > a:hover,
.header-v8 .navbar-nav > .active > a:focus {
  color: #00caca !important;
}
.header-v8 .mega-menu .mega-menu-fullwidth {
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}
.header-v8 .mega-menu .mega-menu-fullwidth .dropdown-link-list li a:hover {
  color: #00caca;
}
.footer-default .footer {
	 color: #dadada;
	 background: #585f69;
	 padding: 20px 0 30px;
}
.footer.margin-top-0 {
	 margin-top: 0;
}
.footer-default .footer p,
.footer-default .footer a {
	 font-size: 14px;
}
.footer-default .footer p {
	 color: #dadada;
}
.footer-default .footer a {
	 color: #72c02c;
}
.footer-default .footer a:hover {
	 color: #5fb611;
  transition: all 0.4s ease-in-out;
}
.footer-default .footer h2,
.footer-default .footer h3,
.footer-default .footer h4 {
	 color: #e4e4e4;
	 background: none;
	 text-shadow: none;
}
.footer-default .footer .footer-subscribe {
	 width: 85%;
}
.footer-default .footer .blog-list {
	 margin: 0;
	 padding: 0;
	 color: #dadada;
	 list-style: none;
}
.footer-default .footer .blog-list li {
	 padding: 0 0;
	 margin-bottom: 7px;
}
.footer-default .footer .blog-list li span {
	 color: #818488;
}
.footer-default .footer .dl-horizontal,
.footer-default .footer .dl-horizontal:hover {
	 border: none !important;
	 background: none !important;
}
.footer-default .footer .posts .dl-horizontal a {
	 color: #dadada;
}
.footer-default .footer .dl-horizontal a:hover {
	 color: #5fb611 !important;
}
.footer-default .footer .dl-horizontal img {
	 border-color: #888 !important;
}
.footer-default .copyright {
 	font-size: 12px;
	 padding: 11px 0 7px;
	 background: #3e4753;
}
.footer-default .copyright p {
	 color: #dadada;
	 margin-top: 12px;
}
.footer-default .copyright a {
	 margin: 0 5px;
	 color: #72c02c;
}
.footer-default .copyright a:hover {
	 color: #5fb611;
}
.footer-default .copyright a img {
	 margin-top: 5px;
}
.footer-v1 .footer {
 	padding: 40px 0;	
	 background: #272727;
}
.footer-v1 .footer p {
	 color: #eee;
}
.footer-v1 .footer h2 {
	 color: #eee;
	 font-size: 18px;
	 font-weight: 200;
}
.footer-v1 .link-list li {
 	border-top: solid 1px #353535;
}
.footer-v1 address,
.footer-v1 .link-list a,
.footer-v1 .link-list i {
 	color: #eee;
}
.footer-v1 address {
	 line-height: 23px;
}
.footer-v1 .footer .posts .dl-horizontal a {
  color: #dadada;
}
.footer-v1 .footer .dl-horizontal a:hover {
  color: #5fb611 !important;
}
.footer-v1 .copyright {
	 background: #c2b08d;
	 border-top: none;
	 padding: 10px 0 5px;
}
.footer-v1 .copyright p {
	 color: #eee;
	 margin-top: 14px;
}
.footer-v1 .copyright a {
	 margin: 0 2px;
}
.footer-v1 .footer-socials {
	 margin-top: 12px;
	 text-align: right;
}
.footer-v1 .footer-socials li a {
	 color: #777;
	 font-size: 16px;
}
.footer-socials a i.fa-facebook {
	padding: 10px;
  border: 1px solid #fff;
}
@media (max-width: 991px) {
	 .footer-v1 .copyright,
	 .footer-v1 .footer-socials {
		  text-align: left;
	 }
}
.footer-v8 .footer {
	 background: #151515;
	 padding: 60px 0 40px;
}
.footer-v8 .footer img.footer-logo {
	 margin-bottom: 30px;
}
.footer-v8 .footer h2 {
	 color: #eee;
	 font-size: 14px;
	 font-weight: 400;
	 line-height: 18px;
	 margin: 2px 0 36px;
	 position: relative;
	 text-transform: uppercase;
}
.footer-v8 .footer h2:after {
	 left: 0;
	 width: 30px;
	 height: 1px;
	 content: " ";
	 bottom: -10px;
	 background: #eee;
	 position: absolute;
}
.footer-v8 .footer p {
	 color: #eee;
	 font-size: 13px;
	 font-weight: 400;
	 line-height: 20px;
}
.footer-v8 .footer strong {
	 color: #eee;
}
.footer-v8 .footer hr {
	 margin: 15px 0;
	 border-color: #222;
}
.footer-v8 .footer .column-one span {
	 color: #eee;
	 display: block;
	 font-size: 13px;
	 font-weight: 600;
	 margin-bottom: 5px;
}
.footer-v8 .footer .column-one a {
	 color: #bbb;
	 font-size: 13px;
}
.footer-v8 .footer .column-one a:hover {
	 text-decoration: none;
}
.footer-v8 .footer .tags-v4 li {
	 line-height: 30px;
}
.footer-v8 .footer .tags-v4 a {
	 color: #eee;
	 border-color: #eee;
}
.footer-v8 .footer .footer-lists {
	 padding-left: 0;
	 list-style: none;
}
.footer-v8 .footer .footer-lists li {
	 display: block;
	 font-size: 13px;
	 margin-bottom: 5px;
}
.footer-v8 .footer .footer-lists li i {
	 color: #bbb;
	 margin-right: 3px;
	 vertical-align: middle;
}
.footer-v8 .footer .footer-lists a {
	 color: #bbb;
}
.footer-v8 .footer .footer-lists li a:hover {
	 text-decoration: none;
}
.footer-v8 .footer .latest-news {
	 overflow: hidden;
}
.footer-v8 .footer .latest-news img {
	 float: left;
	 width: 60px;
	 height: auto;
	 margin: 4px 10px 0 0;
}
.footer-v8 .footer .latest-news h3 {
	 margin: 0 0 3px;
	 font-size: 14px;
	 line-height: 20px;
}
.footer-v8 .footer .latest-news h3 a {
	 color: #fff;
}
.footer-v8 .footer .latest-news h3 a:hover {
	 text-decoration: none;
}
.footer-v8 .footer .latest-news p {
 	font-size: 12px;
	 margin-bottom: 1px;
}
.footer-v8 .footer .form-control {
	 color: #bbb;
	 font-size: 13px;
	 font-weight: 400;
	 min-height: 30px;
	 box-shadow: none;
	 border-color: #222;
	 padding-right: 15px;
	 background: rgba(0,0,0,0.2);
	 border-top-left-radius: 30px !important;
	 border-bottom-left-radius: 30px !important;
}
.footer-v8 .footer .form-control::-moz-placeholder {
	 color: #bbb;
}
.footer-v8 .footer .form-control:-ms-input-placeholder {
	 color: #bbb;
}
.footer-v8 .footer .form-control::-webkit-input-placeholder {
	 color: #bbb;
}
.footer-v8 .footer .input-group-btn .input-btn {
	 color: #fff;
	 border: none;
	 font-size: 13px;
	 margin-left: -1px;
	 padding: 7px 10px;
	 border-top-right-radius: 30px !important;
	 border-bottom-right-radius: 30px !important;
}
.footer-v8 .footer .social-icon-list {
	 padding-left: 0;
	 list-style: none;
}
.footer-v8 .footer .social-icon-list li {
	 padding: 0 3px;
	 display: inline-block;
}
.footer-v8 .footer .social-icon-list li i {
	 color: #fff;
	 width: 30px;
	 height: 30px;
	 padding: 7px;
	 font-size: 14px;
	 text-align: center;
	 line-height: normal;
	 display: inline-block;
	 border: 1px solid #fff;
}
.footer-v8 .footer .social-icon-list li i:hover {
	 transition: all 0.35s, transform 0.35s;
}
.footer-v8 .copyright {
 	padding: 15px 0;
	 background: #000;
}
.footer-v8 .copyright .terms-menu {
	 margin: 5px 0 0;
}
.footer-v8 .copyright .terms-menu li {
	 color: #bbb;
	 font-size: 12px;
	 line-height: 10px;
	 margin-right: 2px;
	 padding-left: 10px;
	 padding-left: 10px;
	 border-left: 1px solid #bbb;
}
.footer-v8 .copyright .terms-menu li:first-child {
 	border-left: none;
 	padding: 0 7px 0 0;
}
.footer-v8 .copyright .terms-menu li a {
	 color: #bbb;
}
.footer-v8 .copyright .terms-menu li a:hover {
	 color: #fff;
	 text-decoration: none;
}
.footer-v8 .footer .column-one a:hover {
	 color: #72c02c;
}
.footer-v8 .footer .tags-v4 a:hover {
	 border-color: #72c02c;
	 background-color: #72c02c;
}
.footer-v8 .footer .footer-lists li a:hover {
	 color: #72c02c;
}
.footer-v8 .footer .latest-news h3 a:hover {
	 color: #72c02c;
}
.footer-v8 .footer .input-group-btn .input-btn {
	 background: #72c02c;
}
.footer-v8 .footer .social-icon-list li i:hover {
	 background: #72c02c;
	 border-color: #72c02c;
}
.modal {
	 z-index: 10000;
}
